<div class="container min-vh-100 d-flex flex-row align-items-center">
    <div class="row">
      <div class="col-md-8 offset-md-2">


        <app-alert-modal [message]="message" *ngIf="message!=''" (close)="message = ''"></app-alert-modal>

        <img src="assets/icons/icon-152x152.png">
        <div class="card">
          <div class="card-header">
            Signup
          </div>
          <div class="card-body">
            <!--
            <form [formGroup]="login" (ngSubmit)="onLogin()">
          <div class="form-group">
  
            <label class="center-block">Username:
  
              <input class="form-control" formControlName="username">
  
            </label>
  
          </div>
            </form>
            -->
            <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
  
              <div class="form-group m-3">
  
                <label class="center-block">
                  Username:
                </label>
  
                <input class="form-control" formControlName="username" placeholder="Username" required>
  
              </div>

              <div class="form-group m-3">
  
                <label class="center-block">
                  Email:
                </label>
  
                <input class="form-control" formControlName="email" placeholder="Email" required>
  
              </div>
  
              <div *ngIf="signupForm.controls['email'].invalid && (signupForm.controls['email'].dirty && signupForm.controls['email'].touched)" class="m-3 alert alert-warning">
  
                  Please insert a valid email
  
              </div>
  
              <div class="form-group m-3">
  
                <label class="center-block">
                  Password:
                </label>
  
                <input class="form-control" formControlName="password" placeholder="Password" required>
  
              </div>
  
              <div *ngIf="signupForm.controls['password'].invalid && (signupForm.controls['password'].dirty && signupForm.controls['password'].touched)" class="m-3 alert alert-warning">
  
                  Please insert a valid password
  
              </div>
  
              <div class="form-group m-3">
  
                <label class="center-block">
                  Confirm password:
                </label>
  
                <input class="form-control" formControlName="password_confirmation" placeholder="Password" required>
  
              </div>
  
              <div *ngIf="signupForm.controls['password_confirmation'].invalid && (signupForm.controls['password_confirmation'].dirty && signupForm.controls['password_confirmation'].touched)" class="m-3 alert alert-warning">
  
                  Passwords are not the same!
  
              </div>
  
              <button class="m-3 btn btn-primary" color="primary" type="submit" [disabled]="!signupForm.valid">Signup</button>
            </form>
  
          
          </div>
        </div>
    </div>
  </div>