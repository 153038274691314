import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../auth.service';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  signupForm: UntypedFormGroup;

  message: string;

  constructor(
    formBuilder: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router
  ) {

    this.message = ''
    this.signupForm = formBuilder.group({
      username: new UntypedFormControl('',  Validators.required),
      email: new UntypedFormControl('', Validators.compose([
        /* Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$'), */
        Validators.required,
        Validators.email
       ])),
      password: new UntypedFormControl('',  Validators.required),
      password_confirmation: new UntypedFormControl('',  Validators.required)

    });
  }

  ngOnInit(): void {
  }

  onSubmit() {

    this.message = '';

    this.auth.registerUser(this.signupForm.value)
    .subscribe(
      res => {
        this.message = res.message;
        localStorage.setItem(environment.ACCESS_TOKEN_CODE, res.token)
        console.log("Response: ",res)
        //this.router.navigate(['/login'])
      },
      error => {
        console.log("error", error);
        this.message = error.error.message;

      }
    )
  }

}
