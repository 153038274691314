import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {

  private user: any;
  private roles: any;
  //private userRoles = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient,
              private _router: Router)
  {

  }

  registerUser(user: any) {
    return this.http.post<any>(environment.API_URL+"register", user)
  }
  /*
  loginUser(user: any) {
    let res = this.http.post<any>(environment.API_URL+"login", user);
    console.log("auth service res: ", res);
    return res;
  }
  */
  loginUser(loginData: any) {
    return this.http
      .post<any>(environment.API_URL+"login", loginData)
      .pipe(
        map((response: any) => {
          localStorage.setItem(environment.ACCESS_TOKEN_CODE, response.token);
          this.user = response.user;
          //this.userRoles = response.user.roles;
          console.log(response.user);
          //console.log("test admin: ", response.user.roles.some((role: { name: string; })=>role.name==='ADMIN'))
          return response;
        })/*,
        catchError((err) => throwError(err))
      */
      );
  }

  async logoutUser() {

    const headers= new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem(environment.ACCESS_TOKEN_CODE))
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'application/json');

    await this.http.get(environment.API_URL+"logout", {headers})
      .subscribe({
        next: (res: any) => {
          localStorage.removeItem(environment.ACCESS_TOKEN_CODE)
        },
        error: (err: any) => {
          console.log("Error: ", err)
        },
        complete: () => {

          this._router.navigate(['/login'])

          console.log("Logout completed")

        }
      })

  }

  //Ma se la pagina viene ricaricata this.user viene perso.
  //Quindi bisogna fare in modo di richiedere nuovamente le informazioni utente se è presente un token in local storage ad ogni chiamata di authservice
  //Per il momento getUser non viene mai utilizzata
  /*
  async getUser() {

    const headers= new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem(environment.ACCESS_TOKEN_CODE))
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'application/json');

    await this.http.get(environment.API_URL+"profile", {headers})
      .subscribe({
        next: (res: any) => {
          this.user = res
          console.log(this.user)
        },
        error: (err: any) => {
          console.log("Error: ", err)
        },
        complete: () => {
          console.log("Get user info completed")
          console.log("User now: ", this.user);
          return this.user;
        }
      })
    return this.user;

    /*
    return this.http
    .get<any>(environment.API_URL+"profile", {headers})
    .pipe(
      map((response: any) => {
        //localStorage.setItem('token', response.token);
        this.user = response;
        console.log("user taken from http");
        //this.userRoles = response.user.roles;
        console.log(response);
        //console.log("test admin: ", response.user.roles.some((role: { name: string; })=>role.name==='ADMIN'))
      })
    )
    */

  //}

  //  isAdmin() {
    //stesso discorso qui per le regole, se non esiste user allora bisogna provare a riprenderlo dalle api
    //return this.user.roles.some((role: { name: string; })=>role.name==='ADMIN')

  //}

  isAdmin(): Promise<boolean> {

    //console.log('called checkAuthenticationAsPromise!');

    return new Promise<boolean>((resolve, reject) => {
      const headers= new HttpHeaders()
        .set('Authorization', 'Bearer ' + localStorage.getItem(environment.ACCESS_TOKEN_CODE))
        .set('Access-Control-Allow-Origin', '*')
        .set('Content-Type', 'application/json');

      this.http.get(environment.API_URL+"profile", {headers})
        .subscribe({
          next: (res: any) => {
            this.roles = res.user.roles
            //console.log(this.roles)
          },
          error: (err: any) => {
            console.log("Error: ", err)
            resolve(false)
          },
          complete: () => {
            //console.log("Get user info for admin check completed")
            //console.log("Roles now: ", this.roles);
            if(this.roles.includes('ADMIN')){
              resolve(true)
            }
            else{
              resolve(false)
            }
            //return this.user;
          }
        })
      /*
      setTimeout(() => {
        resolve(false);
      }, 300)*/

    });
  }

  generateHeader() {
    return new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem(environment.ACCESS_TOKEN_CODE))
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'application/json');
  }

  errorManager(error: any) {
    //Reindirizzamento in caso di ban
    if(
      error.status==401
      &&
      error.error.message==='Unauthenticated.'
    ){

      localStorage.removeItem(environment.ACCESS_TOKEN_CODE)
    }
  }

  loggedIn() {
    return !!localStorage.getItem(environment.ACCESS_TOKEN_CODE)
  }


}
