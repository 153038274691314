import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {

  loading: boolean;
  passwordUpdateSuccess: boolean;
  errorMessage: string;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;

  url: string;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    //private router: Router
  ) {
    this.oldPassword = '';
    this.newPassword = '';
    this.newPasswordConfirm = '';
    this.errorMessage = '';
    this.loading = false;
    this.passwordUpdateSuccess = false;
    this.id = '';
    this.url = '';
  }

  @Input() id: string;
  @Output() close = new EventEmitter<void>();

  ngOnInit(): void {
    this.errorMessage = '';
  }

  onCancelClick() {
    this.errorMessage = '';
    this.close.emit();
  }

  onConfirmClick() {
    this.errorMessage = '';

    if(this.oldPassword==='' && this.id===''){
      this.errorMessage = 'Si prega di compilare tutti i campi';
      this.passwordUpdateSuccess = false;
    }

    if(this.newPassword=== '' || this.newPasswordConfirm===''){
      this.errorMessage = 'Si prega di compilare tutti i campi';
      this.passwordUpdateSuccess = false;
    }

    else if(this.newPassword!==this.newPasswordConfirm){
      this.errorMessage = 'Le password nuove non corrispondono';
      this.passwordUpdateSuccess = false;
    }

    else{

      const headers= this.authService.generateHeader();

      var body = {

      }

      if(this.id){
        this.url = environment.API_URL+"users/"+this.id
        body = {
          'password': this.newPassword,
        }
      }
      else{
        this.url = environment.API_URL+"profile/update-password"
        body = {
          'old_password': this.oldPassword,
          'new_password': this.newPassword
        }
      }

      this.http.put(this.url, body, {headers})
      .subscribe({
        next: (res: any) => {
          this.errorMessage='';
          this.loading = false;

        },
        error: (err: any) => {

          this.loading = false;
          //this.router.navigate(['/profile'], { state: { error: true } });
          this.errorMessage=err.error.message;
          this.passwordUpdateSuccess = false;

          console.log("Update error: ", err)
        },
        complete: () => {

          this.loading = false;

          this.passwordUpdateSuccess = true;

          console.log("Password updated successfully");

          //non sarebbe un errorMessage, però così utilizzo una variabile in meno
          if(!this.id){
            this.errorMessage = 'Password modificata con successo, ora è necessario effettuare il login con la nuova password';
          }
          else{
            this.errorMessage = 'Password modificata con successo, ora l\'utente dovrà effettuare il login con la nuova password';
          }

        }
      })
    }
  }

  logoutRedirect(){

    this.passwordUpdateSuccess = false;
    this.errorMessage = '';
    this.authService.logoutUser(); //Questa funzione esegue anche il redirect

  }

}
