<div *ngIf="success" class="alert alert-success alert-dismissible fade show" role="alert">
    ✔️ &nbsp;Utente aggiornato con successo
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="success=false"></button>
</div>

<div *ngIf="createSuccess" class="alert alert-success alert-dismissible fade show" role="alert">
	✔️ &nbsp;Utente creato con successo
	<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="createSuccess=false"></button>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
    ⚠️ &nbsp;C'è stato un errore durante l'aggiornamento dell'utente
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="error=false"></button>
</div>

<div *ngIf="loading" class="d-flex justify-content-center">
    <div  class="spinner-border text-primary loading-page-spinner" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<app-confirm-modal [message]="message" *ngIf="message!=''" (close)="message = ''" (action)="confirmDeleteUser(user.id)"></app-confirm-modal>

    <div *ngIf="!loading" class="card scrollable">

        <div class="card-header">
			{{user.first_name}}&nbsp;{{user.last_name}}
        </div>

        <div class="card-body" style="height: 64vh;">
            <div class="row">
                <div class="col text-center">

                    <img src="/assets/icons/user.png" height="200">

                </div>
                <div class="col">
                    <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-start">
                        <div class="ms-2 me-auto">
                            <div><b>Nome:</b>&nbsp;{{user.first_name}}</div>

                        </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                                <div><b>Cognome:</b>&nbsp;{{user.last_name}}</div>
                            </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-start">
                          <div class="ms-2 me-auto">
                            <div><b>Codice Fiscale:</b>&nbsp;{{user.fiscal_code}}</div>
                          </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-start">
                          <div class="ms-2 me-auto">
                            <div><b>Codice Ordine dei Medici:</b>&nbsp;{{user.codice_ordine_medici}}</div>
                          </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-start">
                          <div class="ms-2 me-auto">
                            <div><b>Provincia Ordine dei Medici:</b>&nbsp;{{user.provincia_ordine_medici}}</div>
                          </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-start">
                          <div class="ms-2 me-auto">
                            <div *ngIf="user.verified_codice_ordine_medici" class="d-flex"><b>Codice Ordine dei Medici verificato:</b>&nbsp;Sì</div>
                            <div *ngIf="!user.verified_codice_ordine_medici" class="d-flex"><b>Codice Ordine dei Medici verificato:</b>&nbsp;No</div>
                          </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                                <div><b>Email:</b>&nbsp;{{user.email}}</div>
                            </div>
                        </li>

						<!--
                        <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                                <div class="d-flex"><b>Ruoli:</b>&nbsp;<div *ngFor="let role of user.roles">{{role.name}}&nbsp;</div></div>
                            </div>
                        </li>
                        -->
						<!--
                        <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                                <div *ngIf="user.banned" class="d-flex"><b>Bannato:</b>&nbsp;Sì</div>
                                <div *ngIf="!user.banned" class="d-flex"><b>Bannato:</b>&nbsp;No</div>
                            </div>
                        </li>
                        -->

                        <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                                <div *ngIf="user.email_verified_at" class="d-flex"><b>Email verificata:</b>&nbsp;Sì - il {{user.email_verified_at | date :'dd/MM/yyyy hh:mm:ss'}}</div>
                                <div *ngIf="!user.email_verified_at" class="d-flex"><b>Email verificata:</b>&nbsp;No</div>
                            </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                                <div><b>Utente creato il:</b>&nbsp;{{user.created_at | date :'dd/MM/yyyy hh:mm:ss'}}</div>
                            </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                                <div><b>Ultimo aggiornamento:</b>&nbsp;{{user.updated_at | date :'dd/MM/yyyy HH:mm:ss'}}</div>
                            </div>
                        </li>

                    </ul>
                </div>

            </div>

            <!--
            <div class="row mt-5">


                    <div class="col-2 border">Username:</div>
                    <div class="col-4 border">{{user.username}}</div>
                    <div class="col"><button class="btn btn-primary">Change username</button></div>

            </div>

            <div class="row mt-5">

                    <div class="col-2 border">Email:</div>
                    <div class="col-4 border">{{user.email}}</div>
                    <div class="col"><button class="btn btn-primary">Change email</button></div>


            </div>
            -->
        </div>

        <div class="card-footer text-end">
            <button class="btn btn-danger text-uppercase">Elimina Utente</button>
            <button class="btn btn-primary text-uppercase ms-2" (click)="onEditUserClick(user.id)">Modifica Utente</button>
        </div>

    </div>
