import { Component, Input, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.scss']
})

export class AlertModalComponent {

    constructor(
        public router : Router,
        private route : ActivatedRoute
    ){}

    @Input() message: any;
    @Output() close = new EventEmitter<void>();

    onCloseClick() {
        this.close.emit();
    }
}
