import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-add-day-or-lapse-modal',
	templateUrl: './add-day-or-lapse-modal.component.html',
	styleUrls: ['./add-day-or-lapse-modal.component.scss']
})

export class AddDayOrLapseModalComponent implements OnInit {

	addInterval: boolean;

	dayOrLapse: {
		firstDate: string,
		secondDate: string,
	};


	constructor() {
		this.addInterval = false;

		this.dayOrLapse = {
			firstDate: '',
			secondDate: ''
		}
	}

	@Output() close = new EventEmitter<void>();
	@Output() addDayOrLapse = new EventEmitter<Object>()

	ngOnInit(): void {
		this.addInterval = false;
	}

	onCancelClick() {
		this.close.emit();
	}

	compareDates() {
		if(!this.addInterval){
			return false;
		}
		else {
			if(this.dayOrLapse.firstDate!=''&&this.dayOrLapse.secondDate!=''){
				let firstDate = new Date(this.dayOrLapse.firstDate);
				let secondDate = new Date(this.dayOrLapse.secondDate);
				return secondDate <= firstDate;
			}
			else{
				return false;
			}
		}
	}

	public onAddDayOrIntervalClick() {
		//console.log("Event onAddDayOrIntervalClick emitted with: ", this.dayOrLapse);
		this.addDayOrLapse.emit(this.dayOrLapse);
	}

}
