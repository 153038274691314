import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../auth.service";
import {Router} from "@angular/router";

enum FieldAvailability {
	Unverified = 0,
	Available = 1,
	Unavailable = 2
}

@Component({
	selector: 'app-create-user',
	templateUrl: './create-user.component.html',
	styleUrls: ['./create-user.component.scss']
})

export class CreateUserComponent implements OnInit {

	protected readonly isNaN = isNaN;
	protected readonly parseInt = parseInt;

	//TODO: Serve un validatore sia per il codice fiscale che per l'email

	loading: boolean;

	//fiscalCodeAvailable: number; //Avrei voluto usare un boolean ma questa variabile deve avere tre stati
	//emailAvailable: number; //Avrei voluto usare un boolean ma questa variabile deve avere tre stati
	fiscalCodeAvailable: FieldAvailability;
	emailAvailable: FieldAvailability;

	provinces: any;


	user: {
		first_name: string,
		last_name: string,
		fiscal_code: string,
		codice_ordine_medici: string,
		provincia_ordine_medici: string,
		phone_number: string,
		mmg: boolean,
		pls: boolean,
		patients_number: number | null,
		verified_codice_ordine_medici: boolean,
		banned: boolean,
		email_verified: boolean,
		email: string,
		password: string,
		roles: Array<{
			name: string,
			description: string,
			enabled: boolean,
		}>
	};

	passwordConfirm: string;

	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private router: Router
	) {

		this.loading = true;
		this.user = {
			first_name: '',
			last_name: '',
			fiscal_code: '',
			codice_ordine_medici: '',
			provincia_ordine_medici: '',
			phone_number: '',
			mmg: false,
			pls: false,
			patients_number: null,
			verified_codice_ordine_medici: true,
			banned: false,
			email_verified: true,
			email: '',
			password: '',
			roles: []
		};

		this.passwordConfirm = '';

		this.fiscalCodeAvailable=FieldAvailability.Unverified;
		this.emailAvailable= FieldAvailability.Unverified;

		this.provinces = null;

	}

	ngOnInit(): void {
		this.loading = true;
		this.passwordConfirm = '';
		this.fiscalCodeAvailable=FieldAvailability.Unverified;
		this.emailAvailable=FieldAvailability.Unverified;

		const headers = this.authService.generateHeader();

		this.http.get(environment.API_URL+"roles", {headers})
			.subscribe({
				next: (res: any) => {
					this.http.get(environment.API_URL+"provinces", {headers})
						.subscribe({
							next: (res: any) => {
								this.provinces = res;
								this.loading = false;

							},
							error: (err: any) => {

							},
							complete: () => {

							}
						})

					this.user.roles = res;
					this.user.roles.forEach( (role: any) => {

						role.enabled = role.name === 'USER' || role.name === 'DOCTOR';

					});
					console.log(this.user.roles);

				},
				error: (err: any) => {
					console.log("Error: ", err);

				},
				complete: () => {
				}
			})
	}

	onVerifyFiscalCodeOrEmail(propertyName: string, value: string){

		let url = propertyName === 'fiscal_code' ? 'verify-fiscal-code-availability' : 'verify-email-availability';

		if(value!=='') {

			let body = {
				property: propertyName,
				value: value,
			}

			const headers = this.authService.generateHeader();

			this.http.post(environment.API_URL + url, body, {headers})
				.subscribe({
					next: (res: any) => {
						console.log("Response: ", res);

						if (propertyName === 'fiscal_code') {
							res[0] === 'Not found' ? this.fiscalCodeAvailable = FieldAvailability.Available : this.fiscalCodeAvailable = FieldAvailability.Unavailable;
						} else {
							res[0] === 'Not found' ? this.emailAvailable = FieldAvailability.Available : this.emailAvailable = FieldAvailability.Unavailable;
						}

					},
					error: (err: any) => {
						console.log("Error: ", err);
					},
					complete: () => {


					}
				})
		}

	}

	onCreateUser() {
		this.loading = true;
		const headers = this.authService.generateHeader();

		this.http.post(environment.API_URL+"users", this.user, {headers})
			.subscribe({
				next: (res: any) => {
					console.log("Response: ", res);
					this.loading=false;
					this.router.navigateByUrl('/users/view-user/' + res.id, {state: {createSuccess: true}})
						.then(r => {console.log("r", r)});
				},
				error: (err: any) => {
					console.log("Error: ", err);
					this.fiscalCodeAvailable=FieldAvailability.Unverified;
					this.emailAvailable=FieldAvailability.Unverified;
				},
				complete: () => {
					this.loading = false;

				}
			})
	}

}
