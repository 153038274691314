import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from "../../auth.service";

@Component({
  selector: 'app-change-username-modal',
  templateUrl: './change-username-modal.component.html',
  styleUrls: ['./change-username-modal.component.scss']
})
export class ChangeUsernameModalComponent implements OnInit {

  errorMessage: string;
  originalUsername: string;

  url: string;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private router: Router
  ) {
    this.originalUsername = '';
    this.username = '';
    this.id = '';
    this.url = '';
    this.errorMessage = '';
  }

  @Input() username: string;
  @Input() id: string;
  @Output() close = new EventEmitter<void>();

  ngOnInit(): void {
    this.errorMessage = '';
  }

  onCancelClick() {
    this.close.emit();
  }

  onConfirmClick() {
    /*
    if(this.originalUsername===this.username){ //Se non viene cambiato viene semplicemente chiuso il modal
      this.close.emit();
    }*/

    if(!this.username){
      this.errorMessage = "L'username non può essere vuoto"

    }
    else if(this.username.indexOf(' ')>=0){ //controlla la presenza di spazi
      this.errorMessage = "L'username non può contenere spazi"
    }
    else{

      const headers= this.authService.generateHeader();

      const body = {
        'username': this.username,
      }

      if(this.id){
        this.url = environment.API_URL+"users/"+this.id
      }
      else{
        this.url = environment.API_URL+"profile"
      }

      this.http.put(this.url, body, {headers})
      .subscribe({
        next: (res: any) => {
          console.log("Response arrivata: ", res.status);

        },
        error: (err: any) => {
          if(err.status===400){
            this.errorMessage = 'Username già usato da un altro utente, immetterne uno nuovo';
          }
          console.log("Update error: ", err)
        },
        complete: () => {

          this.errorMessage = '';

          this.close.emit();

          if(this.id){
            this.router.navigateByUrl('/users/view-user/'+this.id, { state: { success: true } });
          }
          else{
            this.router.navigate(['/profile'], { state: { success: true } });
          }
          console.log("User updated successfully");
        }
      })
    }
  }

}
