import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth : AuthService, private router : Router){

  }
	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Promise<boolean>
	{

		console.log("AuthGuard called");

		console.log("state.url: ", state.url.substring(1,33));

		if(this.auth.loggedIn()||state.url.substring(1,33)==='substitutions/view-substitution/'){
			return true;
		}
		await this.router.navigate(['login']);
		return false;
	}

}
