<div class="backdrop"></div>
<div class="alert-box">

    <div *ngIf="!armedDeletion" class="mb-3">
        <div class="form-label">Per eliminare il profilo copiare ed incollare il seguente testo: </div>
        <div class="text-center">{{randomText}}</div>
        <input [(ngModel)]="validationText" type="text" class="form-control mt-3" placeholder="Immettere qui il testo visualizzato sopra">
    </div>

    <small *ngIf="errorMessage" class="text-danger">{{errorMessage}}</small>

    <div *ngIf="armedDeletion && !deletionSuccess" class="mb-3">
        <div class="text-center">Si è veramente sicuri di eliminare il proprio profilo?</div>
        <div *ngIf="loading" class="d-flex justify-content-center">
            <div  class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>

    <div *ngIf="deletionSuccess" class="mb-3">
        <div class="text-center text-danger text-uppercase">Profilo eliminato</div>
    </div>

    <div class="alert-box-actions mt-3 text-end">
        
        <button *ngIf="!deletionSuccess" type="button" class="btn btn-warning" (click)="onCancelClick()">Cancel</button>

        <button *ngIf="!armedDeletion" type="button" class="btn btn-danger ms-3 text-right" (click)="onConfirmClick()">Delete</button>

        <button *ngIf="armedDeletion && !deletionSuccess" type="button" class="btn btn-danger ms-3 text-right" (click)="deleteProfile()">Delete</button>

        <button *ngIf="deletionSuccess" type="button" class="btn btn-warning" (click)="redirectLogin()">Ok</button>

    </div>
</div>
