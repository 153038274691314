<div class="card scrollable">

	<div class="card-header ps-0 pe-0">
		<div class="search-collapse col">
			<div class="row p-1">
				<div class="col-auto ms-3" (click)="onShowFiltersClick()" data-bs-toggle="collapse" href="#collapseFilters" role="button" aria-expanded="false" aria-controls="collapseFilters">
					<i class="bi bi-search"></i><i class="bi bi-caret-down-fill"></i>
				</div>
				<div class="col" *ngIf="urlParamId===''&&urlParamType===''&&urlParamLastNameCreatedBy===''&&urlParamExpired===''&&urlParamAddress===''&&urlParamCity===''&&urlParamProvince===''&&urlParamInterestedDocs===''&&urlParamAssignedDoc===''">
					Nessun filtro applicato
				</div>
				<div class="col-auto" *ngIf="urlParamId!==''||urlParamType!==''||urlParamLastNameCreatedBy!==''||urlParamExpired!==''||urlParamAddress!==''||urlParamCity!==''||urlParamProvince!==''||urlParamInterestedDocs!==''||urlParamAssignedDoc!==''">
					Filtri applicati:
				</div>

				<div class="col-auto" >
					<span class="badge bg-success" *ngIf="urlParamId!==''">Id
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('id')"></i>
					</span>
				</div>
				<div class="col-auto" *ngIf="urlParamType!==''">
					<span class="badge bg-success">Tipo
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('type')"></i>
					</span>
				</div>
				<div class="col-auto" *ngIf="urlParamLastNameCreatedBy!==''">
					<span class="badge bg-success">Cognome richiedente
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('lastNameCreatedBy')"></i>
					</span>
				</div>

				<div class="col-auto" *ngIf="urlParamExpired!==''">
					<span class="badge bg-success">Scadenza
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('expired')"></i>
					</span>
				</div>
				<div class="col-auto" *ngIf="urlParamAddress!==''">
					<span class="badge bg-success">Indirizzo
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('address')"></i>
					</span>
				</div>
				<div class="col-auto" *ngIf="urlParamCity!==''">
					<span class="badge bg-success">Città
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('city')"></i>
					</span>
				</div>
				<div class="col-auto" *ngIf="urlParamProvince!==''">
					<span class="badge bg-success">Provincia
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('province')"></i>
					</span>
				</div>

				<div class="col-auto" *ngIf="urlParamInterestedDocs!==''">
					<span class="badge bg-success">Medici interessati
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('interested_docs')"></i>
					</span>
				</div>
				<div class="col-auto" *ngIf="urlParamAssignedDoc!==''">
					<span class="badge bg-success">Medico assegnato
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('assigned_doc')"></i>
					</span>
				</div>

				<div class="col">
				</div>
				<div *ngIf="!loading" class="col text-end me-5">
					Numero di sostituzioni trovate: {{resultsNumber}}
				</div>
			</div>
			<div class="row collapse mt-1 ps-4" [ngClass]="{'show': (showFilters)}"  style="height: 11vh;" id="collapseFilters">
				<div class="row">
					<div class="col input-group mb-3" style="max-width: 20vh">
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchId!==urlParamId && searchId!=='')||(urlParamId!==''&&searchId===''), 'bg-success': urlParamId!=='' && searchId!=='' , 'text-white': ((searchId!==urlParamId && searchId!=='')||(urlParamId!==''&&searchId===''))||(urlParamId!==''&&searchId!=='')}">Id</span>
						<input type="text" (keydown)="onKeydown($event)" class="form-control" [(ngModel)]="searchId">
					</div>
					<div class="col input-group mb-3">
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchType!==urlParamType && searchType!=='')||(urlParamType!==''&&searchType===''), 'bg-success': urlParamType!=='' && searchType!=='' , 'text-white': ((searchType!==urlParamType && searchType!=='')||(urlParamType!==''&&searchType===''))||(urlParamType!==''&&searchType!=='')}">Tipo</span>
						<select (keydown)="onKeydown($event)" class="form-select" aria-label="Type search select" [(ngModel)]="searchType">
							<option selected value=''>Non selezionato</option>
							<option *ngFor="let substitutionType of substitutionTypes" [value]="substitutionType.id">
								{{substitutionType.name}}
							</option>
						</select>
					</div>
					<div class="col input-group mb-3">
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchLastNameCreatedBy!==urlParamLastNameCreatedBy && searchLastNameCreatedBy!=='')||(urlParamLastNameCreatedBy!==''&&searchLastNameCreatedBy===''), 'bg-success': urlParamLastNameCreatedBy!=='' && searchLastNameCreatedBy!=='' , 'text-white': ((searchLastNameCreatedBy!==urlParamLastNameCreatedBy && searchLastNameCreatedBy!=='')||(urlParamLastNameCreatedBy!==''&&searchLastNameCreatedBy===''))||(urlParamLastNameCreatedBy!==''&&searchLastNameCreatedBy!=='')}">Cognome richiedente</span>
						<input type="text" (keydown)="onKeydown($event)" class="form-control" [(ngModel)]="searchLastNameCreatedBy">
					</div>
					<div class="col input-group mb-3" >
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchExpired!==urlParamExpired && searchExpired!=='')||(urlParamExpired!==''&&searchExpired===''), 'bg-success': urlParamExpired!=='' && searchExpired!=='' , 'text-white': ((searchExpired!==urlParamExpired && searchExpired!=='')||(urlParamExpired!==''&&searchExpired===''))||(urlParamExpired!==''&&searchExpired!=='')}">Scadute</span>
						<select (keydown)="onKeydown($event)" class="form-select" aria-label="Expired search select" [(ngModel)]="searchExpired">
							<option selected value=''>Non selezionato</option>
							<option value='true'>Sì</option>
							<option value='false'>No</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="col input-group mb-3">
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchAddress!==urlParamAddress && searchAddress!=='')||(urlParamAddress!==''&&searchAddress===''), 'bg-success': urlParamAddress!=='' && searchAddress!=='' , 'text-white': ((searchAddress!==urlParamAddress && searchAddress!=='')||(urlParamAddress!==''&&searchAddress===''))||(urlParamAddress!==''&&searchAddress!=='')}">Indirizzo</span>
						<input type="text" (keydown)="onKeydown($event)" class="form-control" [(ngModel)]="searchAddress">
					</div>
					<div class="col input-group mb-3">
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchCity!==urlParamCity && searchCity!=='')||(urlParamCity!==''&&searchCity===''), 'bg-success': urlParamCity!=='' && searchCity!=='' , 'text-white': ((searchCity!==urlParamCity && searchCity!=='')||(urlParamCity!==''&&searchCity===''))||(urlParamCity!==''&&searchCity!=='')}">Città</span>
						<input type="text" (keydown)="onKeydown($event)" class="form-control" [(ngModel)]="searchCity">
					</div>
					<div class="col input-group mb-3">
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchProvince!==urlParamProvince && searchProvince!=='')||(urlParamProvince!==''&&searchProvince===''), 'bg-success': urlParamProvince!=='' && searchProvince!=='' , 'text-white': ((searchProvince!==urlParamProvince && searchProvince!=='')||(urlParamProvince!==''&&searchProvince===''))||(urlParamProvince!==''&&searchProvince!=='')}">Provincia</span>
						<select (keydown)="onKeydown($event)" class="form-select" aria-label="Province search select" [(ngModel)]="searchProvince">
							<option selected value=''>Non selezionato</option>
							<option *ngFor="let province of provinces" [value]="province.code">
								{{province.name}}
							</option>
						</select>
					</div>
					<div class="col input-group mb-3" >
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchInterestedDocs!==urlParamInterestedDocs && searchInterestedDocs!=='')||(urlParamInterestedDocs!==''&&searchInterestedDocs===''), 'bg-success': urlParamInterestedDocs!=='' && searchInterestedDocs!=='' , 'text-white': ((searchInterestedDocs!==urlParamInterestedDocs && searchInterestedDocs!=='')||(urlParamInterestedDocs!==''&&searchInterestedDocs===''))||(urlParamInterestedDocs!==''&&searchInterestedDocs!=='')}">Medici interessati</span>
						<select (keydown)="onKeydown($event)" class="form-select" aria-label="InterestedDocs search select" [(ngModel)]="searchInterestedDocs">
							<option selected value=''>Non selezionato</option>
							<option value='true'>Sì</option>
							<option value='false'>No</option>
						</select>
					</div>
					<div class="col input-group mb-3" >
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchAssignedDoc!==urlParamAssignedDoc && searchAssignedDoc!=='')||(urlParamAssignedDoc!==''&&searchAssignedDoc===''), 'bg-success': urlParamAssignedDoc!=='' && searchAssignedDoc!=='' , 'text-white': ((searchAssignedDoc!==urlParamAssignedDoc && searchAssignedDoc!=='')||(urlParamAssignedDoc!==''&&searchAssignedDoc===''))||(urlParamAssignedDoc!==''&&searchAssignedDoc!=='')}">Medico assegnato</span>
						<select (keydown)="onKeydown($event)" class="form-select" aria-label="AssignedDoc search select" [(ngModel)]="searchAssignedDoc">
							<option selected value=''>Non selezionato</option>
							<option value='true'>Sì</option>
							<option value='false'>No</option>
						</select>
					</div>
					<div class="col text-end"><button class="btn btn-primary text-uppercase" (click)="onApplyFilters()">Applica Filtri</button></div>
				</div>
				</div>

			</div>
	</div>

	<div class="card-body p-0" [style.height]="showFilters? '64vh' : '76vh'">

		<div *ngIf="loading" class="d-flex justify-content-center">
			<div  class="spinner-border text-primary loading-page-spinner" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>

		<div *ngIf="!loading&&substitutions.length==0" class="d-flex justify-content-center">
			<div  class="text-primary mt-5">
				Nessuna sostituzione trovata con i filtri selezionati
			</div>
		</div>

		<div class="table-responsive" style="overflow: auto; height: 100%;">
			<table *ngIf="!loading" class="table table-bordered table-responsive table-striped">
				<thead>
					<tr>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='id')}" (click)="onOrderByClick('id')">
							<!--<div class="row column-header">
								<div class="col text-start">
									Id
								</div>
								<div class="col text-end">-->
							<i *ngIf="orderBy!='id'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='id'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='id'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Id
						</th>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='type')}" (click)="onOrderByClick('type')">
							<i *ngIf="orderBy!='type'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='type'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='type'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Tipo
						</th>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='requested_by')}" (click)="onOrderByClick('requested_by')">
							<i *ngIf="orderBy!='requested_by'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='requested_by'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='requested_by'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Medico richiedente
						</th>
						<th class="column-header" scope="col" style="width: 200px!important;">
							&nbsp;Date
						</th>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='expiration')}" (click)="onOrderByClick('expiration')">
							<i *ngIf="orderBy!='expiration'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='expiration'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='expiration'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Scadenza
						</th>
						<th class="column-header" scope="col">
							&nbsp;Indirizzo
						</th>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='province')}" (click)="onOrderByClick('province')">
							<i *ngIf="orderBy!='province'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='province'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='province'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Provincia
						</th>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='created_at')}" (click)="onOrderByClick('created_at')">
							<i *ngIf="orderBy!='created_at'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='created_at'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='created_at'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Data inserimento
						</th>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='salary')}" (click)="onOrderByClick('salary')">
							<i *ngIf="orderBy!='salary'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='salary'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='salary'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Compenso
						</th>
						<th class="column-header" scope="col">
							&nbsp;Medici interessati
						</th>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='assigned_doc')}" (click)="onOrderByClick('assigned_doc')">
							<i *ngIf="orderBy!='assigned_doc'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='assigned_doc'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='assigned_doc'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Medico assegnato
						</th>

						<th class="column-header" scope="col">
							Azioni
						</th>

					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let substitution of substitutions">
						<th scope="row">{{substitution.id}}</th>
						<td>{{substitution.type.name}}</td>
						<td ><a routerLink="/users/view-user/{{substitution.user.id}}">{{substitution.user.first_name}}&nbsp;{{substitution.user.last_name}}</a></td>
						<td>
							<div *ngFor="let date of getList(substitution.substitution_dates)">
								{{date.start|date:'dd/MM/yyyy'}} - {{date.end|date:'dd/MM/yyyy'}}
							</div>
						</td>
						<td>{{substitution.expiration|date:'dd/MM/yyyy'}}</td>
						<td>
							<div *ngIf="substitution.type.id===1">
								<div *ngFor="let clinic of getList(substitution.clinics)">
									{{clinic.address}}, {{clinic.house_number}}, {{clinic.city}}
								</div>
							</div>
							<div *ngIf="substitution.type.id!==1">
								{{substitution.address}}
							</div>
						</td>
						<td>{{substitution.province}}</td>
						<td>{{substitution.created_at|date:'dd/MM/yyyy hh:mm'}}</td>
						<td>{{substitution.salary}}&nbsp;&euro;
							<div *ngIf="substitution.type.id===1||substitution.type.id===2"> al giorno</div>
							<div *ngIf="substitution.type.id!==1&&substitution.type.id!==2"> all'ora</div>
						</td>
						<td>
							<div *ngIf="substitution.interested_doctors.length>0">
								Presenti
							</div>
							<div *ngIf="substitution.interested_doctors.length===0" class="text-danger fw-bold">
								Nessuno
							</div>
						</td>
						<td>
							<a *ngIf="substitution.assigned_doc_id!==null" routerLink="/users/view-user/{{substitution.assigned_doc_id}}">
								{{substitution.assigned_doctor.first_name}}&nbsp;{{substitution.assigned_doctor.last_name}}
							</a>
							<div *ngIf="substitution.assigned_doc_id===null" class="text-danger fw-bold">
								Nessuno
							</div>
						</td>

						<td>

							<button class="btn btn-primary text-uppercase table-button" (click)="onViewSubstitutionClick(substitution.id)"><i class="bi bi-eye-fill"></i></button>
							<!--
							<button class="btn btn-primary text-uppercase ms-3">Edit User</button>
							-->
						</td>
					</tr>
				</tbody>
			</table>

		</div>
	</div>

	<div class="card-footer" style="height: 8vh">
		<div class="row w-100">
			<!--GESTIONE PAGINAZIONE-->
			<div class="col d-flex">
				<div class="mt-1 ms-5">Elementi per pagina:&nbsp;</div>
				<select class="form-select" style="max-width: 100px; max-height: 35px;" [(ngModel)]="paginationItems" (ngModelChange)="onPaginationItemsChange()">
					<option value="10">10</option>
					<option value="20">20</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>
			</div>

			<nav *ngIf="!loading" class="col-auto" aria-label="Page navigation">
				<ul class="pagination">
					<li *ngIf="currentPage>1" class="page-item me-3" (click)='onPaginatorClick(currentPage-1)'><a class="page-link" > < </a></li> <!--Deve scomparire se siamo a pagina 1-->
					<li class="page-item" [ngClass]="{'active': (currentPage===1)}"><a class="page-link" (click)='onPaginatorClick(1)'>1</a></li> <!--Pagina 1 la lasciamo SEMPRE visibile-->
					<li *ngIf="currentPage>3" class="page-item"><a class="page-link" (click)='onPaginatorClick(currentPage-2)'>...</a></li> <!--Deve comparire se la pagina è > 3-->

					<li *ngIf="currentPage<=3 && lastPage>1" class="page-item" [ngClass]="{'active': (currentPage===2)}" (click)='onPaginatorClick(2)'><a class="page-link">2</a></li>
					<li *ngIf="currentPage>3" class="page-item"><a class="page-link" (click)='onPaginatorClick(currentPage-1)'>{{(currentPage-1)}}</a></li>

					<li *ngIf="currentPage<=3 && lastPage>2" [ngClass]="{'active': (currentPage===3)}" class="page-item" (click)='onPaginatorClick(3)'><a class="page-link">3</a></li>
					<li *ngIf="currentPage>3 && currentPage!==lastPage" class="page-item active"><a class="page-link">{{currentPage}}</a></li>

					<li *ngIf="currentPage<=3 && lastPage>3 && lastPage!==4" class="page-item" (click)='onPaginatorClick(4)'><a class="page-link" >4</a></li>
					<li *ngIf="currentPage>3 && currentPage!==(lastPage-1) && currentPage!==lastPage" class="page-item"><a class="page-link" (click)='onPaginatorClick(currentPage+1)'>{{nextPage}}</a></li>

					<li *ngIf="currentPage<=(lastPage-3) && lastPage!==4" class="page-item"><a class="page-link" (click)='onPaginatorClick(currentPage+2)'>...</a></li> <!--Deve comparire se manca una pagina tra quelle visualizzate e l'ultima-->
					<li *ngIf="lastPage>3" class="page-item" [ngClass]="{'active': (currentPage===lastPage)}"><a class="page-link" (click)='onPaginatorClick(lastPage)'>{{lastPage}}</a></li> <!--Deve comparire se le pagine sono più di 3-->
					<li *ngIf="currentPage!==lastPage" class="page-item ms-3" (click)='onPaginatorClick(currentPage+1)'><a class="page-link"> > </a></li> <!--Deve scomparire se siamo all'ultima pagina-->
				</ul>
			</nav>

			<div class="col text-end"><button class="btn btn-primary text-uppercase" routerLink="/substitutions/new-substitution">Nuova Sostituzione</button></div>
		</div>
	</div>

</div>
