import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../auth.service";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";

@Component({
	selector: 'app-new-substitution',
	templateUrl: './new-substitution.component.html',
	styleUrls: ['./new-substitution.component.scss']
})

export class NewSubstitutionComponent implements OnInit {

	message: string;
	loading: boolean;

	substitutionTypes: any;
	provinces: any;

	miniDoctorsList: any;

	requestingDocSet: boolean; //Forse si può togliere, per ora inutilizzata

	showAddDayOrLapseModal: boolean;

	refreshListCompareString: string;

	selectedDoctor : any;

	subTypeError: string;

	subAddress: string | null; //Only needed for Sostituzioni Centro Prelievi or Other Substitution types
	subCity: string | null; //Only needed for Sostituzioni Centro Prelievi or Other Substitution types
	subProvince: string | null; //Only needed for Sostituzioni Centro Prelievi or Other Substitution types

	newSubstitution: {
		type: string;
		requestingDoctorId: number | null;
		requestingDoctorString: string;
		dates: any[];
		address: string | null;
		province: string | null;
		otherInfo: string | null;
		patientsNumber: number | null;
		managementSoftware: string | null;
		salary: number | null;
		flagPriorityList: boolean;
		subHourStart: string | null;
		subHourEnd: string | null;
		city: string | null; //TODO: Da accorpare con address e rimuovere insieme al vecchio database
	}

	clinics: any[];

	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private router: Router
	) {
		this.message = '';
		this.loading = true;

		this.substitutionTypes = this.provinces = null;

		this.newSubstitution = {
			type: '',
			requestingDoctorId: null,
			requestingDoctorString: '',
			dates: [],
			address: null,
			province: null,
			otherInfo: null,
			patientsNumber: null,
			managementSoftware: null,
			salary: null,
			flagPriorityList: false,
			subHourStart: null,
			subHourEnd: null,
			city: null, //TODO: Da accorpare con address e rimuovere insieme al vecchio database
		}

		this.requestingDocSet = false; //Forse si può togliere, per ora inutilizzata

		this.showAddDayOrLapseModal = false;

		this.refreshListCompareString = '';

		this.selectedDoctor = null;

		this.subTypeError = '';

		this.clinics = [];

		this.subAddress = null;
		this.subCity = null;
		this.subProvince = null;

	}

	//TODO: gestire l'errore in caso di campi vuoti

	ngOnInit(): void {

		this.loading = true;

		this.showAddDayOrLapseModal = false;

		this.refreshListCompareString = '';

		this.selectedDoctor = null;

		this.subTypeError = '';

		const headers= this.authService.generateHeader();

		let url = environment.API_URL+"get-substitution-types-and-provinces";

		this.http.get(url, {headers})
			.subscribe({
				next: (res: any) => {

					console.log("Res: ", res);

					this.provinces = res.provinces;

					this.substitutionTypes = res.substitution_types;

					console.log("Sub types:", this.substitutionTypes);

				},
				error: (err: any) => {
					console.log("Error: ", err)
				},
				complete: () => {
					this.loading = false;
				}
			})

	}

	public addDayOrLapse(dates: any) {
		console.log("Dates received: ", dates);
		console.log("New Substitution now:", this.newSubstitution);
		this.newSubstitution.dates.push(dates);
		this.showAddDayOrLapseModal=false;
	}



	//Non proprio ortodossa ma funziona
	refreshMiniDoctorsList() {

		const headers = this.authService.generateHeader();

		let url = environment.API_URL + "datalist-doctors-search";

		this.refreshListCompareString = '';

		this.http.post(url, {search: this.newSubstitution.requestingDoctorString}, {headers})
			.subscribe({
				next: (res: any) => {
					this.miniDoctorsList = res.doctors;
					//compareString = '';
					for (let doctor of this.miniDoctorsList) {
						this.refreshListCompareString = doctor.first_name + ' ' + doctor.last_name + ' - ' + doctor.fiscal_code + ' - OdM ' + doctor.provincia_ordine_medici;
						if (this.newSubstitution.requestingDoctorString === this.refreshListCompareString) {

							this.clinics = [];

							console.log("datalist trigger 2");
							this.miniDoctorsList = [];
							this.newSubstitution.requestingDoctorId = doctor.id;

							this.selectedDoctor = doctor;
							this.checkForMMGorPLS();

							this.loading = true;
								//Chiamata per le clinics e per il numero pazienti

							this.http.get(environment.API_URL + "get-doctor-clinics/"+doctor.id.toString(), {headers})
								.subscribe({
									next: (res: any) => {

										this.clinics = res.clinics;

										if(this.clinics.length===0){
											//TODO: errore ambulatori assenti
										}
										else{
											this.newSubstitution.province = this.clinics[0].province;

										}
										if(res.patientsNumber!==null){
											this.newSubstitution.patientsNumber = res.patients_number;
										}

										this.loading = false;
									},
									error: (err: any) => {
										console.log("Error getting doctor clinics: ", err)
										this.loading = false;
									},
									complete: () => {
									}
									}
								)

							console.log("substitution now: ", this.newSubstitution);
						}
					}
				},
				error: (err: any) => {
					console.log("Error: ", err)
				},
				complete: () => {
				}
			})


	}

	checkForMMGorPLS() {
		this.subTypeError = '';
		if(
			(
				this.newSubstitution.requestingDoctorId!==null
				&&
				this.newSubstitution.type==="1"
				&&
				this.selectedDoctor.mmg===0
				)
		){
			this.subTypeError = "Attenzione! Il medico "+this.selectedDoctor.first_name+" "+this.selectedDoctor.last_name
			+" non è registrato nei nostri sistemi come MMG, è necessario modificare il suo profilo o selezionare un altro medico."
		}
		else if(
			this.newSubstitution.requestingDoctorId!==null
			&&
			this.newSubstitution.type==="2"
			&&
			this.selectedDoctor.pls===0
		){
			this.subTypeError = "Attenzione! Il medico "+this.selectedDoctor.first_name+" "+this.selectedDoctor.last_name
				+" non è registrato nei nostri sistemi come pediatra, è necessario modificare il suo profilo o selezionare un altro medico."
		}
	}

	removeDateOrLapse(index: any) {
		this.newSubstitution.dates.splice(index, 1);
	}

	createSubstitutionRequest() {
		const headers = this.authService.generateHeader();
		if(this.newSubstitution.type=='3'||this.newSubstitution.type=='4'){
			this.newSubstitution.address = this.subAddress+", "+this.subCity;
			this.newSubstitution.province = this.subProvince;
			this.newSubstitution.city = this.subCity;
		}
		this.http.post(environment.API_URL+'create-substitution', {new_substitution: this.newSubstitution}, {headers})
			.subscribe({
				next: (res: any) => {
					console.log("response: ", res);
					this.router.navigateByUrl('/substitutions', { state: { success: true } });
				},
				error: (error: any) => {
					console.log("error", error);
					this.router.navigateByUrl('/substitutions', { state: { error: true } });
				},
				complete: () => {

				}
			})



	}

}
