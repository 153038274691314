// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //API_URL: 'https://y3ct2h.duckdns.org/api/',
  APP_NAME: 'findoc-backoffice',
  API_URL: 'https://api.findoc.duckdns.org/api/',
  HOST: 'https://backoffice.findoc.duckdns.org',
  //ACCESS_TOKEN_CODE is the name of the variable in which the login token will be stored in the browser's local storage
  ACCESS_TOKEN_CODE: 'findoc-backofficeA4Op8vCMATYc3aF', //This need to be regenerated for every distinct app
  DEFAULT_PAGINATION_ITEMS: 10,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
