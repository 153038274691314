<div class="container-fluid">

    <button type="button" routerLink="/profile" class="btn btn-info btn-lg">Profilo</button>

    <button type="button" routerLink="/users" class="btn btn-success btn-lg ms-5">Utenti</button>

	<button type="button" routerLink="/substitutions" class="btn btn-success btn-lg ms-5">Sostituzioni</button>

	<app-home-map></app-home-map>

</div>

