<div *ngIf="loading" class="d-flex justify-content-center">
    <div  class="spinner-border text-primary loading-page-spinner" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<app-confirm-modal [message]="message" *ngIf="message!=''" (close)="message = ''" (action)="updateProfile()"></app-confirm-modal>

<app-change-username-modal [username]="user.username" *ngIf="showChangeUsernameModal" (close)="showChangeUsernameModal = false"></app-change-username-modal>

<app-change-email-modal [email]="user.email" *ngIf="showChangeEmailModal" (close)="showChangeEmailModal = false"></app-change-email-modal>

<app-change-password-modal *ngIf="showChangePasswordModal" (close)="showChangePasswordModal = false"></app-change-password-modal>

<app-delete-profile-modal *ngIf="showDeleteProfileModal" (close)="showDeleteProfileModal = false"></app-delete-profile-modal>

<div *ngIf="!loading" class="card">

    <div class="card-header">
        <xng-breadcrumb></xng-breadcrumb>
    </div>

    <div class="card-body">
        <div class="row">
            <div class="col text-center">

                <img src="/assets/icons/user.png" height="200">

            </div>
            <div class="col">

                <div class="mb-3">
                    <label for="firstNameEditInput" class="form-label">First name:</label>
                    <input [(ngModel)]="user.first_name" type="text" class="form-control" id="firstNameEditInput" placeholder="Non può essere vuoto">
                </div>


                <div class="mb-3">
                    <label for="lastNameEditInput" class="form-label">Last name:</label>
                    <input [(ngModel)]="user.last_name" type="text" class="form-control" id="lastNameEditInput" placeholder="Non può essere vuoto">
                </div>
                <!--
                <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div><b>First Name:</b>&nbsp;{{user.first_name}}</div>
                        
                    </div>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-start">
                        <div class="ms-2 me-auto">
                            <div><b>Last name:</b>&nbsp;{{user.last_name}}</div>
                        </div>
                    </li>

                </ul>
            -->
            </div>
        
        </div>

        <div class="row mt-5">


            <div class="col-2 border">Username:</div>
            <div class="col-4 border">{{user.username}}</div>
            <div class="col"><button class="btn btn-primary" (click)="openChangeUsernameModal()">Change username</button></div>

        </div>

        <div class="row mt-5">

            <div class="col-2 border">Email:</div>
            <div class="col-4 border">{{user.email}}</div>
            <div class="col"><button class="btn btn-primary" (click)="openChangeEmailModal()">Change email</button></div>

        </div>

        <div class="row mt-5">

            <div class="col-2"></div>
            <div class="col-4"></div>
            <div class="col"><button class="btn btn-primary" (click)="openChangePasswordModal()">Change password</button></div>
            
        </div>
    </div>

    <div class="card-footer row">
        <button class="btn btn-danger text-uppercase col-auto text-start" (click)="openDeleteProfileModal()">Delete Profile</button>
        <div class="col"></div>
        <button class="btn btn-primary text-uppercase ms-2 col-auto text-end" (click)="showUpdateConfirmModal()">Save Profile</button>
    </div>

</div>