<div class="custom-sidenav" [ngClass]="visible ? 'show' : 'hide'">

    <div class="link-item" routerLink="/" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <i class="bi bi-house-fill"></i> Home
    </div>

    <div *ngIf="isAdmin" class="link-item" routerLink="/substitutions" routerLinkActive="active-link">
        <i class="bi bi-person-fill"></i> Sostituzioni
    </div>

	<div *ngIf="isAdmin" class="link-item" routerLink="/users" routerLinkActive="active-link">
		<i class="bi bi-person-fill"></i> Utenti
	</div>

    <!--
    <div class="container list-group ">
        <a href="#" class="list-group-item text-truncate"
            ><i class="bi bi-bootstrap"></i> <span>Item 1</span> </a>

        <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate"
            data-bs-parent="#sidebar"><i class="bi bi-film"></i> <span>Item</span></a>
        <a href="#" class=" p-0 list-group-item border-end-0 d-inline-block text-truncate"
            data-bs-parent="#sidebar"><i class="bi bi-heart"></i> <span>Item</span></a>
        <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate"
            data-bs-parent="#sidebar"><i class="bi bi-bricks"></i> <span>Item</span></a>
        <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate"
            data-bs-parent="#sidebar"><i class="bi bi-clock"></i> <span>Item</span></a>
        <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate"
            data-bs-parent="#sidebar"><i class="bi bi-archive"></i> <span>Item</span></a>
        <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate"
            data-bs-parent="#sidebar"><i class="bi bi-gear"></i> <span>Item</span></a>
        <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate"
            data-bs-parent="#sidebar"><i class="bi bi-calendar"></i> <span>Item</span></a>
        <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate"
            data-bs-parent="#sidebar"><i class="bi bi-envelope"></i> <span>Item</span></a>
        <button class="p-0 btn btn-danger" (click)="logout()">Logout</button>

    </div>
-->
</div>
