<div class="header">
    <app-header></app-header>
</div>

<div class="body">

    <div id="sidenav">
        <app-sidenav></app-sidenav>
    </div>

    <div id="content">

        <!-- <xng-breadcrumb></xng-breadcrumb> -->

        <div class="page">
            <router-outlet></router-outlet>
        </div>
    </div>

    <!--
    <div class="footer">

    </div>
    -->

</div>
