<div class="backdrop"></div>
<div class="alert-box">
    <div class="mb-3">
        <label for="UsernameEditInput" class="form-label">Immettere nuovo username:</label>
        <input [(ngModel)]="username" type="text" class="form-control" id="UsernameEditInput" placeholder="Non può essere vuoto">
        <small *ngIf="errorMessage" class="text-danger">{{errorMessage}}</small>
    </div>
    <div class="alert-box-actions mt-3 text-end">
        
        <button type="button" class="btn btn-danger" (click)="onCancelClick()">Cancel</button>

        <button type="button" class="btn btn-primary ms-3 text-right" (click)="onConfirmClick()">Ok</button>

    </div>
</div>
