import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;

  loading: boolean;

  message: string;

  response: any;

// SE C'È IL TOKEN BISOGNA REINDIRIZZARE ALLA HOME
  constructor(
    formBuilder: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router) {

    this.message = '';
    this.loading = false;

    this.loginForm = formBuilder.group({
      email: new UntypedFormControl('', Validators.compose([
        /* Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$'), */
        Validators.required,
        Validators.email
       ])),
      password: new UntypedFormControl('',  Validators.required)
    });
  }

  ngOnInit(): void {
  }

  async onSubmit() {

    this.loading = true;
    //console.log(this.loginForm.value);
    /*
    this.auth.loginUser(this.loginForm.value)
    .subscribe( //TODO: Usare un altro metodo al posto del subscribe
      res => {
        localStorage.setItem('token', res.token) //sostituire 'token' con un nome univoco che utilizzerà solo quest'app
        console.log("Response: ",res)
        this.router.navigate(['/home'])
      },
      err => console.log(err) //Il subscribe con più di due argomenti di funzione è stato deprecato perchè "poco leggibile" https://rxjs.dev/deprecations/subscribe-arguments (mah...)
    )*/

    
    this.auth.loginUser(this.loginForm.value)
      .subscribe({
        //TODO: Usare un altro metodo al posto del subscribe, oppure usare il subscribe per un solo metodo
        next: (res) => {
          //localStorage.setItem('token', res.token); //sostituire 'token' con un nome univoco che utilizzerà solo quest'app
          //localStorage.setItem('app_user', JSON.stringify(res.user)); //idem come sopra
          //console.log("Response: ", res);
          this.loading = false;
          this.router.navigate(['']);
        },
        error: (error) => {
          this.loading = false;
          console.log("error", error);
          this.message = error.message;
        },
        complete: () => {
            console.info('login completed');
            this.loading = false;
          }
        }
      
      )
    /*
    this.auth.loginUser(this.loginForm.value)
    .subscribe(
      res => {
        localStorage.setItem('token', res.token) //sostituire 'token' con un nome univoco che utilizzerà solo quest'app
        console.log("Response: ",res)
        this.router.navigate(['/home'])
      }
    )*/

  }


}
