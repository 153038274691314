<div class="backdrop"></div>
<div class="alert-box">
    <div>{{message}}</div>
    <div class="alert-box-actions mt-3 text-end">
        
        <button type="button" class="btn btn-danger" (click)="onCancelClick()">Cancel</button>

        <button type="button" class="btn btn-primary ms-3 text-right" (click)="onConfirmClick()">Ok</button>

    </div>
</div>