import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ActivatedRoute, Router } from '@angular/router';
import {AuthService} from "../../../auth.service";

@Component({
	selector: 'app-view-user',
	templateUrl: './view-user.component.html',
	styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {

	user: any;

	loading: boolean;

	message: string;

	userDeleted: boolean;

	success: boolean;
	createSuccess: boolean;
	error: boolean;

	constructor(
		private activatedRoute: ActivatedRoute,
		private authService: AuthService,
		private http: HttpClient,
		private router: Router
	) {
		this.success = false;
		this.createSuccess = false;
		this.error = false;
		this.user = null;
		this.loading = true;
		this.message = '';
		this.userDeleted = false;

		if (this.router.getCurrentNavigation()!.extras.state) { //occhio al non-null assertion operator, il punto esclamativo
			this.success = this.router.getCurrentNavigation()!.extras!.state!['success']; //occhio al non-null assertion operator, il punto esclamativo
			this.createSuccess = this.router.getCurrentNavigation()!.extras!.state!['createSuccess']; //occhio al non-null assertion operator, il punto esclamativo
			this.error = this.router.getCurrentNavigation()!.extras!.state!['error']; //occhio al non-null assertion operator, il punto esclamativo
			//this.emailVerification = this.router.getCurrentNavigation()!.extras!.state!['emailVerification']; //occhio al non-null assertion operator, il punto esclamativo
		}

	}

	async ngOnInit(): Promise<any> {

	this.loading = true;
	this.message = '';
	this.userDeleted = false;

	console.log("ID arrivato: ", this.activatedRoute.snapshot.paramMap.get('id'))

	const headers = this.authService.generateHeader();

	this.http.get(environment.API_URL+"users/"+this.activatedRoute.snapshot.paramMap.get('id'), {headers})
		.subscribe({
			next: (res: any) => {
				this.user = res
				console.log(this.user)
			},
			error: (err: any) => {
				console.log("Error: ", err)
			},
			complete: () => {
			  this.loading = false;
			}
		})
	}

	onEditUserClick(id: number){
		this.router.navigate(['users/edit-user/'+id]);
	}

	showDeleteUserConfirm() {
		this.message = "Si è sicuri di eliminare l'utente?";
	}

	confirmDeleteUser(id: number) {

		if(this.userDeleted){
			this.message = '';
		}
		else{
			const headers= this.authService.generateHeader();
			//this.loading = true;
			console.log("User delete called");
			this.http.delete(environment.API_URL+"users/"+id, {headers})
				.subscribe({
					next: (res: any) => {
						console.log("User deleted successfully");

						this.loading = false;

						this.userDeleted = true;
						this.message = 'Utente eliminato correttamente';
					},
					error: (err: any) => {
						console.log("Error: ", err)
						this.message = 'Si è verificato un errore durante l\'eliminazione dell\'utente, riprovare più tardi';
					},
					complete: () => {


					}
				})

		}
	}


}
