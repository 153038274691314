import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from "../../auth.service";


@Component({
  selector: 'app-change-email-modal',
  templateUrl: './change-email-modal.component.html',
  styleUrls: ['./change-email-modal.component.scss']
})
export class ChangeEmailModalComponent implements OnInit {

  loading: boolean;
  errorMessage: string;
  originalEmail: string;
  url: string;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private router: Router
  ) {
    this.originalEmail = '';
    this.email = '';
    this.id = '';
    this.url = '';
    this.errorMessage = '';
    this.loading = false;
  }

  @Input() email: string;
  @Input() id: string;
  @Output() close = new EventEmitter<void>();

  ngOnInit(): void {
    this.errorMessage = '';
  }

  onCancelClick() {
    this.close.emit();
  }

  onConfirmClick() {

    console.log("onConfirmClick() with email: ", this.email);

    this.loading = true;

    if(!this.email){
      this.errorMessage = "L'email non può essere vuota"
    }
    else if(this.email.indexOf(' ')>=0){ //controlla la presenza di spazi
      this.errorMessage = "L'email non può contenere spazi"
    }
    else{

      const headers= this.authService.generateHeader();

      const body = {
        'email': this.email
      }

      if(this.id){
        this.url = environment.API_URL+"users/"+this.id
      }
      else{
        this.url = environment.API_URL+"profile"
      }

      this.http.put(this.url, body, {headers})
      .subscribe({
        next: () => {
          this.errorMessage='';
          this.loading = false;

        },
        error: (err: any) => {

          this.loading = false;
          //this.router.navigate(['/profile'], { state: { error: true } });
          this.errorMessage=err.error.message;

          console.log("Update error: ", err)
        },
        complete: () => {

          this.loading = false;

          this.errorMessage='';
          //this.loading = false;
          this.router.navigate(['/profile'], { state: { emailVerification: true } });

          console.log("User updated successfully");
        }
      })
    }
  }

}
