import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../auth.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import { Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-view-substitution',
	templateUrl: './view-substitution.component.html',
	styleUrls: ['./view-substitution.component.scss']
})
export class ViewSubstitutionComponent implements OnInit {

	substitution: any;

	loading: boolean;

	message: string;

	substitutionDeleted: boolean;

	isLoggedIn: boolean;

	alreadyAssignedDoctor: boolean;

	success: boolean;
	error: boolean;
	constructor(
		private activatedRoute: ActivatedRoute,
		private authService: AuthService,
		private meta: Meta,
		private http: HttpClient,
		private router: Router
	) {
		this.success = false;
		this.error = false;
		this.substitution = null;
		this.loading = true;
		this.message = '';
		this.substitutionDeleted = false;
		this.alreadyAssignedDoctor = false;
		this.isLoggedIn = this.authService.loggedIn();

		if (this.router.getCurrentNavigation()!.extras.state) { //occhio al non-null assertion operator, il punto esclamativo
			this.success = this.router.getCurrentNavigation()!.extras!.state!['success']; //occhio al non-null assertion operator, il punto esclamativo
			this.error = this.router.getCurrentNavigation()!.extras!.state!['error']; //occhio al non-null assertion operator, il punto esclamativo
		}
	}

	ngOnInit(): void {
		this.loading = true;
		this.message = '';
		this.substitutionDeleted = false;

		this.isLoggedIn = this.authService.loggedIn();

		const headers = this.authService.generateHeader();

		let url = 'substitution-details/';

		if(!this.authService.loggedIn()){
			url = 'substitution-details-public/';
		}

		this.http.get(environment.API_URL+url+this.activatedRoute.snapshot.paramMap.get('id'), {headers})
			.subscribe({
				next: (res: any) => {
					if(res['already_assigned_doctor']==true&&!this.isLoggedIn){
						this.alreadyAssignedDoctor=true;
						console.log("Already assigned doctor");
					}
					else {
						this.alreadyAssignedDoctor=false;
						this.substitution = res.substitution;


						let descriptionStringMeta = this.substitution.type.name+"\n";

						descriptionStringMeta += this.substitution.province.name+"\n";

						for(let date of this.substitution.substitution_dates){
							descriptionStringMeta += date.start+"-";
							descriptionStringMeta += date.end+"\n";
						}

						this.meta.updateTag({property: 'og:title', content: this.substitution.type.name});
						this.meta.updateTag({property: 'og:url', content: 'https://'+environment.HOST+this.router.url});
						this.meta.updateTag({property: 'og:description', content: descriptionStringMeta});
						console.log(this.router.url);
						console.log(this.substitution);
					}

				},
				error: (err: any) => {
					console.log("Error: ", err)
				},
				complete: () => {
					this.loading = false;
				}
			})
	}

	checkExpired(expiration_date: string){
		let x = new Date(expiration_date);
		let y = new Date();
		return x<y;
	}

	confirmDeleteSubstitution(id: number) {

		if(this.substitutionDeleted){
			this.message = '';
		}
		else{
			const headers= this.authService.generateHeader();
			//this.loading = true;
			console.log("Substitution delete called");
			this.http.delete(environment.API_URL+"substitution/"+id, {headers})
				.subscribe({
					next: (res: any) => {
						console.log("Substitution deleted successfully");

						this.loading = false;

						this.substitutionDeleted = true;
						this.message = 'Sostituzione eliminata correttamente';
					},
					error: (err: any) => {
						console.log("Error: ", err)
						this.message = 'Si è verificato un errore durante l\'eliminazione della sostituzione, riprovare più tardi';
					},
					complete: () => {


					}
				})

		}
	}

}
