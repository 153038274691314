<div *ngIf="loading" class="d-flex justify-content-center">
	<div  class="spinner-border text-primary loading-page-spinner" role="status">
		<span class="visually-hidden">Loading...</span>
	</div>
</div>

<div *ngIf="!loading" class="card scrollable">

	<!--
	  <div class="card-header">
		  <xng-breadcrumb></xng-breadcrumb>
	  </div>
	-->

	<div class="card-body px-5 pt-2" style="height: 80vh;">
		<div class="row">

			<div class="col">
				<div class="d-flex input-group">
					<span class="input-group-text input-label-custom">Nome</span>
					<input [(ngModel)]="user.first_name" type="text" maxlength="254" class="form-control" placeholder="Non può essere vuoto">
				</div>


				<div class="mt-4 d-flex">
					<span class="input-group-text input-label-custom">Cognome</span>
					<input [(ngModel)]="user.last_name" type="text" maxlength="254" class="form-control" placeholder="Non può essere vuoto">
				</div>

				<div class="mt-4 d-flex">
					<span class="input-group-text input-label-custom">Codice Fiscale</span>
					<input [(ngModel)]="user.fiscal_code" (ngModelChange)="fiscalCodeAvailable = 0" type="text" maxlength="254" class="form-control" placeholder="Inserisci e poi verifica disponibilità">
					<button class="btn-primary text-nowrap" (click)="onVerifyFiscalCodeOrEmail('fiscal_code', user.fiscal_code)">Verifica disponibilità</button>
				</div>
				<div class="mt-1">
					<div *ngIf="fiscalCodeAvailable===1" class="text-success">Codice Fiscale disponibile</div>
					<div *ngIf="fiscalCodeAvailable===0&&user.fiscal_code!==''" class="text-danger">Verifica la disponibilità del codice fiscale prima di creare un nuovo utente con questo codice fiscale</div>
					<div *ngIf="fiscalCodeAvailable===2" class="text-danger">Codice Fiscale già associato ad un utente presente nei nostri sistemi, non si può creare un altro utente con lo stesso codice fiscale</div>
				</div>

				<div class="d-flex" [ngClass]="user.fiscal_code==='' ? 'mt-4' : 'mt-1'">
					<span class="input-group-text input-label-custom">Email</span>
					<input [(ngModel)]="user.email" (ngModelChange)="(emailAvailable=0)" type="text" maxlength="254" class="form-control" placeholder="Inserisci e poi verifica disponibilità">
					<button class="btn-primary text-nowrap" (click)="onVerifyFiscalCodeOrEmail('email', user.email)">Verifica disponibilità</button>
				</div>
				<div class="mb-4">
					<div *ngIf="emailAvailable===1" class="text-success">Email disponibile</div>
					<div *ngIf="emailAvailable===0&&user.email!==''" class="text-danger">Verifica la disponibilità prima di creare un nuovo utente con questa email</div>
					<div *ngIf="emailAvailable===2" class="text-danger">Email già associata ad un utente presente nei nostri sistemi, provare con un altro indirizzo email</div>
				</div>

				<div class="mt-4 d-flex">
					<span class="input-group-text input-label-custom" style="min-width: 25vh !important;">Codice Ordine dei Medici</span>
					<input [(ngModel)]="user.codice_ordine_medici" type="text" maxlength="254" class="form-control" placeholder="Non può essere vuoto">
				</div>

				<div class="mt-4 d-flex">
					<span class="input-group-text input-label-custom" style="min-width: 25vh !important;">Provincia Ordine dei Medici</span>
					<select class="form-select" aria-label="Province search select" [(ngModel)]="user.provincia_ordine_medici">
						<option selected value=''>Non selezionato</option>
						<option *ngFor="let province of provinces" [value]="province.code">
							{{province.name}}
						</option>
					</select>
				</div>

				<div class="mt-4 d-flex">
					<span class="input-group-text input-label-custom" style="min-width: 25vh !important;">Numero di telefono</span>
					<input [(ngModel)]="user.phone_number" type="text" maxlength="254" class="form-control" placeholder="Facoltativo per la registrazione, obbligatorio per inserire nuove sostituzioni">
				</div>





				<div class="d-flex w-100" [ngClass]="user.email==='' ? 'mt-4' : 'mt-1'">
					<div class="table-responsive">
						<table *ngIf="!loading" class="table table-bordered table-responsive">
							<tbody>
							<tr>
								<th>Questo medico è un Medico di Medicina Generale (MMG)</th>
								<th><input [(ngModel)]="user.mmg" class="form-check-input banned-check" type="checkbox" id="flexSwitchCheckMMG"></th>
							</tr>
							<tr>
								<th>Questo medico è un Pediatra di Libera Scelta (PLS)</th>
								<th><input [(ngModel)]="user.pls" class="form-check-input banned-check" type="checkbox" id="flexSwitchCheckPLS"></th>
							</tr>
							<tr>
								<th>Codice Ordine dei Medici Verificato</th>
								<th><input [(ngModel)]="user.verified_codice_ordine_medici" class="form-check-input banned-check" type="checkbox" id="flexSwitchCheckEmailVerified"></th>
							</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="d-flex w-100" >
					<span class="input-group-text input-label-custom-password-and-confirm">Password</span>
					<input [(ngModel)]="user.password" maxlength="254" type="password" class="form-control" placeholder="Inserisci una password">
				</div>

				<div class="mt-4 d-flex w-100">
					<span class="input-group-text input-label-custom-password-and-confirm">Conferma Password</span>
					<input [(ngModel)]="passwordConfirm" maxlength="254" type="password" class="form-control" placeholder="Inserisci nuovamente la password">
				</div>
				<div *ngIf="user.password!==passwordConfirm&&passwordConfirm!==''" class="text-danger">Le password non corrispondono</div>

			</div>

			<div class="col-auto text-center w-25 me-5">

				<img *ngIf="!(this.user.fiscal_code.length >= 11 && !isNaN(parseInt(this.user.fiscal_code.substring(9, 11))) && parseInt(this.user.fiscal_code.substring(9, 11)) > 32)" src="/assets/icons/user_icon_man.png" height="250" alt="User profile picture male">

				<img *ngIf="(user.fiscal_code.length >= 11 && !isNaN(parseInt(this.user.fiscal_code.substring(9, 11))) && parseInt(this.user.fiscal_code.substring(9, 11)) > 32)" src="/assets/icons/user_icon_woman.png" height="250" alt="User profile picture female">


				<p class="mt-5"><a href="https://portale.fnomceo.it/cerca-prof/index.php" target="_blank">Anagrafica FNOMCeO per verifica codice OdM</a></p>

				<!--
				<button class="btn-primary mt-5">Set profile picture</button>
				-->
				<button *ngIf="user.mmg||user.pls" class="btn-primary mt-5">Aggiungi un ambulatorio</button>

			</div>

		</div>

		<!--

		<div class="row mt-2 h5 justify-content-center">ROLES</div>

		<div class="row mt-2">
			<div class="table-responsive" style="overflow: auto">
				<table *ngIf="!loading" class="table table-bordered table-responsive">
					<thead>
					<tr>
						<th scope="col">Name</th>
						<th scope="col">Description</th>
						<th scope="col">Enabled</th>

					</tr>
					</thead>

					<tbody>
					<tr *ngFor="let role of user.roles">
						<th scope="row">{{role.name}}</th>
						<td>{{role.description}}</td>
						<td>
							<div class="form-check form-switch">
								<input *ngIf="role.name!=='USER'" [(ngModel)]="role.enabled"  class="form-check-input"  type="checkbox">
								<input *ngIf="role.name==='USER'" [(ngModel)]="role.enabled" class="form-check-input" type="checkbox" disabled>
							</div>
						</td>
					</tr>
					</tbody>

				</table>
			</div>
		</div>
		-->

	</div>

	<div class="card-footer text-end">
		<button class="btn btn-danger text-uppercase mb-3" routerLink="/users">Cancel</button>
		<button class="btn btn-primary text-uppercase ms-2 mb-3 me-3" (click)="onCreateUser()"
				[disabled]="user.first_name===''||user.last_name===''||user.fiscal_code===''||!fiscalCodeAvailable||user.email===''||!emailAvailable||user.password===''||passwordConfirm!==user.password">Create New User</button>
	</div>

</div>
