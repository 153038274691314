import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from "../../auth.service";

@Component({
  selector: 'app-delete-profile-modal',
  templateUrl: './delete-profile-modal.component.html',
  styleUrls: ['./delete-profile-modal.component.scss']
})
export class DeleteProfileModalComponent implements OnInit {

  randomText: string;
  validationText: string;
  errorMessage: string;
  loading: boolean;
  armedDeletion: boolean;
  deletionSuccess: boolean;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private router: Router
  ) {
    this.randomText = Math.random().toString(36).substring(2,12);
    this.validationText = '';
    this.errorMessage = '';
    this.loading = false;
    this.armedDeletion = false;
    this.deletionSuccess = false;
  }

  @Output() close = new EventEmitter<void>();

  ngOnInit(): void {
    this.loading = false
    this.validationText = '';
    this.errorMessage = '';
    this.armedDeletion = false;
  }

  onCancelClick() {
    this.armedDeletion = false;
    this.close.emit();
  }

  onConfirmClick() {

    if(this.randomText!==this.validationText){
      this.errorMessage="Si prega di copiare ed incollare il testo visualizzato"
    }
    else{

      this.armedDeletion = true;
      console.warn("Warning, deletion trigger armed");

      this.validationText = Math.random().toString(36).substring(2,12);
      this.errorMessage = '';
    }
  }

  deleteProfile() {

    console.log("deleteProfile called");

    this.loading = true;

    const headers= this.authService.generateHeader();

    console.log("Delete called");
    this.http.delete(environment.API_URL+"profile", {headers})
    .subscribe({
      next: (res: any) => {

      },
      error: (err: any) => {
        console.log("Error: ", err)
      },
      complete: () => {

        localStorage.clear();

        console.log("User deleted successfully");

        this.loading = false;

        this.deletionSuccess = true;

      }
    })

  }

  redirectLogin(){
    this.router.navigate(['/login']);
  }


}
