import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {BreadcrumbModule} from 'xng-breadcrumb';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';

import { AuthService } from './auth.service';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SidenavService } from './components/sidenav/sidenav.service';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './home/profile/profile.component';
import { UsersComponent } from './home/users/users.component';
import { EditProfileComponent } from './home/profile/edit-profile/edit-profile.component';
import { ProfileLayoutComponent } from './layouts/main-layout/profile-layout/profile-layout.component';
import { ChangeUsernameModalComponent } from './components/change-username-modal/change-username-modal.component';
import { ChangeEmailModalComponent } from './components/change-email-modal/change-email-modal.component';
import { EmailUpdateComponent } from './email-update/email-update.component';
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';
import { DeleteProfileModalComponent } from './components/delete-profile-modal/delete-profile-modal.component';
import { ViewUserComponent } from './home/users/view-user/view-user.component';
import { UsersLayoutComponent } from './layouts/main-layout/users-layout/users-layout.component';
import { EditUserComponent } from './home/users/edit-user/edit-user.component';
import { SubstitutionsComponent } from './home/substitutions/substitutions.component';
import { SubstitutionsLayoutComponent } from './layouts/main-layout/substitutions-layout/substitutions-layout.component';
import { ViewSubstitutionComponent } from './home/substitutions/view-substitution/view-substitution.component';
import { NewSubstitutionComponent } from './home/substitutions/new-substitution/new-substitution.component';
import { AddDayOrLapseModalComponent } from './components/add-day-or-lapse-modal/add-day-or-lapse-modal.component';
import { CreateUserComponent } from './home/users/create-user/create-user.component';
import { HomeMapComponent } from './components/home-map/home-map.component';

@NgModule({
  declarations: [
    AppComponent,
    AlertModalComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    HeaderComponent,
    SidenavComponent,
    MainLayoutComponent,
    AuthLayoutComponent,
    ProfileComponent,
    EditProfileComponent,
    ConfirmModalComponent,
    UsersComponent,
    ProfileLayoutComponent,
    ChangeUsernameModalComponent,
    ChangeEmailModalComponent,
    ChangePasswordModalComponent,
    EmailUpdateComponent,
    DeleteProfileModalComponent,
    ViewUserComponent,
    UsersLayoutComponent,
    EditUserComponent,
    SubstitutionsComponent,
    SubstitutionsLayoutComponent,
    ViewSubstitutionComponent,
    NewSubstitutionComponent,
    AddDayOrLapseModalComponent,
    CreateUserComponent,
    HomeMapComponent,
  ],
  imports: [
    AppRoutingModule,
    BreadcrumbModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [AuthService, SidenavService],
  bootstrap: [AppComponent]
})
export class AppModule { }
