import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService
    ) {
    }


  ngOnInit(): void {
    this.breadcrumbService.set('@Home', { skip: true }); // using alias '@Home' don't show breadcrumb in home page
  }

  ngOnDestroy(): void {
    this.breadcrumbService.set('@Home', { skip: false }); // using alias '@Home' don't show breadcrumb in home page
  }


}
