import { Injectable } from '@angular/core';
import { SidenavComponent } from './sidenav.component';

@Injectable()
export class SidenavService {
    private sidenav!: SidenavComponent;

    public setSidenav(sidenav: SidenavComponent) {
        this.sidenav = sidenav;
    }

    public toggle(): void {
        this.sidenav.toggle();
    }

}
