import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../auth.service";
import {HttpClient} from "@angular/common/http";

@Component({
	selector: 'app-substitutions',
	templateUrl: './substitutions.component.html',
	styleUrls: ['./substitutions.component.scss']
})
export class SubstitutionsComponent implements OnInit {

	loading: boolean;
	substitutions: any;
	currentPage: number;
	/*
	I had to add nextPage variable because there is this bug:
	If I had to display the previous page, I can use currentPage-1 and DOM correctly uses
	currentPage as a number, subtracts 1 from currentPage and displays the number as the result of the arithmetic operation
	but if, using the same logic, I had to display the next page and I use currentPage+1, DOM does not use
	currentPage as a number, but as a string, so displays the number 1 aside currentPage
	e.g.
	currentPage=5
	currentPage-1 is displayed correctly as 4
	currentPage+1 is displayed as 51, not as 6
 	*/
	nextPage: number;
	lastPage: number;
	paginationItems: number;
	orderBy: string;
	orderDirection: string;
	resultsNumber: number;
	showFilters: boolean;

	//Containers for select in filters
	substitutionTypes: any;
	provinces: any;

	urlParamId: string;
	urlParamType: string;
	urlParamLastNameCreatedBy: string;
	urlParamExpired: string;
	urlParamAddress: string;
	urlParamCity: string;
	urlParamProvince: string;
	urlParamInterestedDocs: string;
	urlParamAssignedDoc:string;

	searchId: string;
	searchType: string;
	searchLastNameCreatedBy: string;
	searchExpired: string;
	searchAddress: string;
	searchCity: string;
	searchProvince: string;
	searchInterestedDocs: string;
	searchAssignedDoc: string;

	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.loading = true;

		this.substitutionTypes = null;

		this.substitutions = null;

		this.currentPage = this.nextPage = this.lastPage = 1;

		this.paginationItems = environment.DEFAULT_PAGINATION_ITEMS;
		//Inizializzazione selettori d'ordinamento:
		this.orderBy = '';
		this.orderDirection = 'asc';

		this.resultsNumber = 0;

		this.showFilters = false;

		this.urlParamId = this.urlParamType = this.urlParamLastNameCreatedBy = this.urlParamExpired = this.urlParamAddress = this.urlParamCity = this.urlParamProvince = this.urlParamInterestedDocs = this.urlParamAssignedDoc  = '';

		this.searchId = this.searchType = this.searchLastNameCreatedBy = this.searchExpired = this.searchAddress = this.searchCity = this.searchProvince = this.searchInterestedDocs = this.searchAssignedDoc = '';

	}

	ngOnInit(): void {

		this.loading = true;
		this.resultsNumber = 0;

		this.showFilters = (localStorage.getItem(environment.ACCESS_TOKEN_CODE+"_show_filters"))==='true';
		/*
		It's VERY important to maintain the second argument "|| environment.DEFAULT_PAGINATION_ITEMS"
		otherwise Angular returns an error due to the nullable nature of localStorage.getItem
		 */
		this.paginationItems = +(localStorage.getItem(environment.ACCESS_TOKEN_CODE+"_pagination_items")||environment.DEFAULT_PAGINATION_ITEMS);

		this.currentPage = 1;
		//Inizializzazione selettori d'ordinamento:
		this.orderBy = '';
		this.orderDirection = 'asc';

		const headers= this.authService.generateHeader();

		// Correctness check of search params
		let urlError = false;

		let url = environment.API_URL+"get-all-substitutions?page="+this.currentPage+"&pagination="+this.paginationItems;

		this.route.queryParams
			.subscribe(params => {
				if (params['page'] !== undefined) {
					this.currentPage = params['page'];

					urlError = isNaN(Number(this.currentPage)) || ((+this.currentPage) < 1);

				}
				if (params['order_by'] !== undefined) {
					this.orderBy = params['order_by'];

					urlError = urlError || (this.orderBy !== 'id' && this.orderBy !== 'type' && this.orderBy !== 'requested_by' && this.orderBy !== 'expiration' && this.orderBy !== 'province' && this.orderBy !== 'created_at' && this.orderBy !== 'salary' && this.orderBy !== 'assigned_doc');

				}

				if (params['order_direction'] !== undefined) {
					this.orderDirection = params['order_direction'];

					/*
					Edited to accept only asc or desc parameters, CAUTION: This only works if default this.orderDirection
					is set to 'asc' previously in ngOnInit()
					It's also a first control for errors in page, order_by and order_direction parameters
					*/
					if ((this.orderDirection !== 'asc' && this.orderDirection !== 'desc')) {
						console.error('Url params not correctly formatted, resetting and reloading page...');
						this.orderBy = '';
						this.onPaginatorClick(1);
					}

				}

				url = environment.API_URL+"get-all-substitutions?page="+this.currentPage+"&pagination="+this.paginationItems;

				if(this.orderBy!==''&&this.orderBy!=undefined){
					url = url+"&order_by="+this.orderBy+"&order_direction="+this.orderDirection;
				}

				if(params['id']!==undefined){
					this.urlParamId = this.searchId = params['id'];
					url = url+"&id="+this.searchId;
					urlError = urlError||isNaN(Number(this.urlParamId))||((+this.urlParamId)<1);
				}
				if(params['type']!==undefined){
					this.urlParamType = this.searchType = params['type'];
					url = url+"&type_id="+this.searchType;
					urlError = urlError||isNaN(Number(this.urlParamType))||((+this.urlParamType)<1);
				}
				if(params['last_name_created_by']!==undefined){
					this.urlParamLastNameCreatedBy = this.searchLastNameCreatedBy = params['last_name_created_by'];
					url = url+"&last_name_created_by="+this.searchLastNameCreatedBy;
					urlError = urlError||this.urlParamLastNameCreatedBy.length>255;
				}
				if(params['expired']!==undefined){
					this.urlParamExpired = this.searchExpired = params['expired'];
					this.urlParamExpired==='true' ? this.searchExpired = 'true' : this.searchExpired = 'false';
					url = url+"&expired="+this.searchExpired;
					urlError = urlError||(this.urlParamExpired!=='true'&&this.urlParamExpired!=='false');
				}
				if(params['address']!==undefined){
					this.urlParamAddress = this.searchAddress = params['address'];
					url = url+"&address="+this.searchAddress;
					urlError = urlError||this.urlParamAddress.length>255;
				}
				if(params['city']!==undefined){
					this.urlParamCity = this.searchCity = params['city'];
					url = url+"&city="+this.searchCity;
					urlError = urlError||this.urlParamCity.length>255;
				}
				if(params['province']!==undefined){
					this.urlParamProvince = this.searchProvince = params['province'];
					url = url+"&province="+this.searchProvince;
					urlError = urlError||this.urlParamProvince.length!==2;
				}
				if(params['interested_docs']!==undefined){
					this.urlParamInterestedDocs = this.searchInterestedDocs = params['interested_docs'];
					this.urlParamInterestedDocs==='true' ? this.urlParamInterestedDocs = 'true' : this.urlParamInterestedDocs = 'false';
					url = url+"&interested_docs="+this.searchInterestedDocs;
					urlError = urlError||(this.urlParamInterestedDocs!=='true'&&this.urlParamInterestedDocs!=='false');
				}
				if(params['assigned_doc']!==undefined){
					this.urlParamAssignedDoc = this.searchAssignedDoc = params['assigned_doc'];
					this.urlParamAssignedDoc==='true' ? this.urlParamAssignedDoc = 'true' : this.urlParamAssignedDoc = 'false';
					url = url+"&assigned_doc="+this.searchAssignedDoc;
					urlError = urlError||(this.urlParamAssignedDoc!=='true'&&this.urlParamAssignedDoc!=='false');
				}

				if(urlError){
					console.error('Url params not correctly formatted, resetting and reloading page...');
					this.orderBy='';
					this.onPaginatorClick(1);
				}

			})



		this.http.get(url, {headers})
			.subscribe({
				next: (res: any) => {

					console.log("Res: ", res);

					this.provinces = res.provinces;

					this.substitutionTypes = res.substitution_types;

					console.log("Sub types:", this.substitutionTypes);

					this.substitutions = res.substitutions.data;

					/*
					This is needed to reload page if someone tries to pass a page parameter superior at the last page value
					 */
					if(this.substitutions.length===0&&this.currentPage>1){
						this.onPaginatorClick(1);
					}

					this.currentPage = parseInt(res.substitutions.current_page);
					this.nextPage = this.currentPage+1;
					this.lastPage = parseInt(res.substitutions.last_page);

					this.resultsNumber = parseInt(res.substitutions.total);

					console.log(this.substitutions)
				},
				error: (err: any) => {
					console.log("Error: ", err)
				},
				complete: () => {
					this.loading = false;
				}
			})

	}

	onViewSubstitutionClick(id: number){
		this.router.navigate(['substitutions/view-substitution/'+id])
			.then(r => {
				//If the lazy loading fails, reload the page
				//TODO: Qui bisognerebbe mostrare un messaggio d'errore
				if(!r){
					window.location.reload();
				}
			});
	}

	onOrderByClick(parameter: string){
		//console.log("Order direction: ", this.orderDirection);
		if(this.orderDirection==='asc'&&(this.orderBy===''||this.orderBy==undefined)){
			this.orderBy=parameter;
		}
		else {
			if(this.orderBy!==parameter){
				this.orderBy=parameter;
				this.orderDirection='asc';
			}
			else if(this.orderDirection==='asc'){
				this.orderBy=parameter;
				this.orderDirection='desc';
			}
			else{
				this.orderBy='';
			}
		}

		this.onPaginatorClick(1);

	}

	onShowFiltersClick() {

		this.showFilters ? this.showFilters=false : this.showFilters=true;

		localStorage.setItem(environment.ACCESS_TOKEN_CODE+"_show_filters", this.showFilters.toString());

	}

	onKeydown(event: any) {
		if (event.key === "Enter") {
			this.onApplyFilters();
		}
	}

	onApplyFilters() {
		this.loading = true;

		let queryParams: any = {};

		queryParams.page = 1;

		if(this.searchId!==''){
			queryParams.id=this.searchId;
		}
		if(this.searchType!==''){
			queryParams.type=this.searchType;
		}
		if(this.searchLastNameCreatedBy!==''){
			queryParams.last_name_created_by=this.searchLastNameCreatedBy;
		}
		if(this.searchExpired!==''){
			queryParams.expired=this.searchExpired;
		}
		if(this.searchAddress!==''){
			queryParams.address=this.searchAddress;
		}

		if(this.searchCity!==''){
			queryParams.city=this.searchCity;
		}

		if(this.searchProvince!==''){
			queryParams.province=this.searchProvince;
		}

		if(this.searchInterestedDocs!==''){
			queryParams.interested_docs=this.searchInterestedDocs;
		}

		if(this.searchAssignedDoc!==''){
			queryParams.assigned_doc=this.searchAssignedDoc;
		}



		this.router.navigate(['/substitutions'], {queryParams: queryParams})
			.then(r => {
				/*
					If the lazy loading is successful, call ngOnInit, if not, force page reloading
				 */
				//r ? this.ngOnInit() : window.location.reload();
				//TODO: Qui bisognerebbe usare il lazy loading
				window.location.reload();
			});
	}

	removeFilter(filter: string){
	switch (filter) {
		case 'id':
			this.searchId = '';
			break;
		case 'type':
			this.searchType = '';
			break;
		case 'lastNameCreatedBy':
			this.searchLastNameCreatedBy = '';
			break;
		case 'expired':
			this.searchExpired = '';
			break;
		case 'address':
			this.searchAddress = '';
			break;
		case 'city':
			this.searchCity = '';
			break;
		case 'province':
			this.searchProvince = '';
			break;
		case 'interested_docs':
			this.searchInterestedDocs = '';
			break;
		case 'assigned_doc':
			this.searchInterestedDocs = '';
			break;
		default:
			break;
		}
		this.onApplyFilters();
	}

	onPaginationItemsChange() {

		localStorage.setItem(environment.ACCESS_TOKEN_CODE+"_pagination_items", this.paginationItems.toString());

		this.currentPage==1 ? this.ngOnInit() : this.onPaginatorClick(1);

	}

	onPaginatorClick(page: number) {

		this.loading = true;

		let queryParams: any = {};

		queryParams.page = page;

		if(this.orderBy!==''){
			queryParams.order_by=this.orderBy
			queryParams.order_direction=this.orderDirection //Default is asc
		}

		if(this.searchId!==''){
			queryParams.id=this.searchId;
		}
		if(this.searchType!==''){
			queryParams.type=this.searchType;
		}
		if(this.searchLastNameCreatedBy!==''){
			queryParams.last_name_created_by=this.searchLastNameCreatedBy;
		}
		if(this.searchExpired!==''){
			queryParams.expired=this.searchExpired;
		}
		if(this.searchAddress!==''){
			queryParams.address=this.searchAddress;
		}

		if(this.searchCity!==''){
			queryParams.city=this.searchCity;
		}

		if(this.searchProvince!==''){
			queryParams.province=this.searchProvince;
		}

		if(this.searchInterestedDocs!==''){
			queryParams.interested_docs=this.searchInterestedDocs;
		}

		if(this.searchAssignedDoc!==''){
			queryParams.assigned_doc=this.searchAssignedDoc;
		}


		this.router.navigate(['/substitutions'], {queryParams: queryParams})
			.then(r => {
				/*
					If the lazy loading is successful, call ngOnInit, if not, force page reloading
				 */
				r ? this.ngOnInit() : window.location.reload();
			});
	}



	/*
	Utility function to do ngFor inside ngFor
	 */
	getList(value: any) {
		return value;
	}


}
