<div *ngIf="success" class="alert alert-success alert-dismissible fade show" role="alert">
	✔️ &nbsp;Sostituzione aggiornata con successo
	<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="success=false"></button>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
	⚠️ &nbsp;C'è stato un errore durante l'aggiornamento della sostituzione
	<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="error=false"></button>
</div>

<div *ngIf="loading" class="d-flex justify-content-center">
	<div  class="spinner-border text-primary loading-page-spinner" role="status">
		<span class="visually-hidden">Loading...</span>
	</div>
</div>

<app-confirm-modal [message]="message" *ngIf="message!=''" (close)="message = ''" (action)="confirmDeleteSubstitution(substitution.id)"></app-confirm-modal>

<div *ngIf="!loading" class="card scrollable">

	<div class="card-header fw-bolder" *ngIf="!(!isLoggedIn&&alreadyAssignedDoctor)">
		<h3>{{substitution.type.name}}</h3>
	</div>

	<div class="card-body" style="height: 64vh;">

		<h3 class='text-center mt-lg-5' *ngIf="!isLoggedIn&&alreadyAssignedDoctor">
			Questa richiesta di sostituzione è già stata evasa, grazie per l'interessamento!
		</h3>

		<table class="table" *ngIf="!(!isLoggedIn&&alreadyAssignedDoctor)">
			<tbody>
				<tr>
					<td><b>Medico richiedente:</b></td>
					<td><a routerLink="/users/view-user/{{substitution.user_id}}">{{substitution.user.first_name}}&nbsp;{{substitution.user.last_name}}</a></td>
				</tr>
				<tr *ngIf="substitution.type_id<3&&substitution.user.patients_number!==null">
					<td><b>Numero pazienti:</b></td>
					<td>{{substitution.user.patients_number}}</td>
				</tr>
				<tr>
					<td><b>Date:</b></td>
					<td>
						<div *ngFor="let date of substitution.substitution_dates">
							{{date.start|date:'dd/MM/yyyy'}} - {{date.end|date:'dd/MM/yyyy'}}
						</div>
					</td>
				</tr>
				<tr>
					<td><b>Altre informazioni:</b></td>
					<td>{{substitution.other_info}}</td>
				</tr>
				<tr *ngIf="substitution.type_id>2">
					<td><b>Indirizzo:</b></td>
					<td>{{substitution.address}}</td>
				</tr>
				<tr>
					<td><b>Provincia di riferimento:</b></td>
					<td>{{substitution.province.code}}&nbsp;({{substitution.province.name}})</td>
				</tr>
				<tr>
					<td><b>Salario:</b></td>
					<td>{{substitution.salary}}&nbsp;&euro;</td>
				</tr>
				<tr>
					<td><b>Scadenza:</b></td>
					<td>{{substitution.expiration|date:'dd/MM/yyyy'}}
						<b *ngIf="checkExpired(substitution.expiration)">&nbsp;-&nbsp;</b>
						<b class="text-success" *ngIf="substitution.assigned_doc_id!==null&&checkExpired(substitution.expiration)">&nbsp;Evasa</b>
						<b class="text-danger" *ngIf="substitution.assigned_doc_id===null&&checkExpired(substitution.expiration)">&nbsp;Scaduta</b>
					</td>
				</tr>
				<tr *ngIf="substitution.interested_doctors!=undefined&&substitution.interested_doctors.length>0">
					<td><b>Medici interessati:</b></td>
					<td>
						<div *ngFor="let doctor of substitution.interested_doctors">
							<a routerLink="/users/view-user/{{doctor.user.id}}">
								{{doctor.user.first_name}}&nbsp;{{doctor.user.last_name}}
							</a>
						</div>
					</td>
				</tr>
				<tr *ngIf="substitution.interested_doctors!=undefined&&substitution.interested_doctors.length===0">
					<td><b>Medici interessati:</b></td>
					<td class="text-danger fw-bold">Nessuno</td>
				</tr>
				<tr>
					<td><b>Medico assegnato:</b></td>
					<td *ngIf="substitution.assigned_doc_id"><a routerLink="/users/view-user/{{substitution.assigned_doc_id}}">{{substitution.assigned_doctor.first_name}}&nbsp;{{substitution.assigned_doctor.last_name}}</a></td>
					<td *ngIf="substitution.assigned_doc_id===null" class="text-danger fw-bold">Nessuno</td>
				</tr>
				<tr *ngIf="isLoggedIn">
					<td><b>Creata il:</b></td>
					<td>{{substitution.created_at|date:'dd/MM/yyyy HH:mm:ss'}}</td>
				</tr>
				<tr *ngIf="isLoggedIn">
					<td><b>Ultimo aggiornamento:</b></td>
					<td>{{substitution.updated_at|date:'dd/MM/yyyy HH:mm:ss'}}</td>
				</tr>

			</tbody>
		</table>

		<div class="text-center" *ngIf="!(!isLoggedIn&&alreadyAssignedDoctor)">Numero ambulatori: {{substitution.clinics.length}}</div>

		<div class="row" *ngIf="!(!isLoggedIn&&alreadyAssignedDoctor)">
			<div *ngFor="let clinic of substitution.clinics" class="col card">
				<div class="card-body">
					<h5>Ambulatorio</h5>
					<p>{{clinic.address}}&nbsp;{{clinic.house_number}}, {{clinic.zip_code}}&nbsp;{{clinic.city}}&nbsp;({{clinic.province}})</p>
				</div>
			</div>
		</div>

	</div>

</div>
