import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  //user_json = localStorage.getItem('app_user');
  user: any;
  success: boolean;
  error: boolean;
  emailVerification: boolean;
  message: string;
  loading: boolean;
  isAdmin: boolean;
  //routeState: any;

  //userSubscription: Subscription

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router
  ) {
    this.user = null;
    this.success = false;
    this.error = false;
    this.emailVerification = false;
    this.message = '';
    this.loading = true;
    this.isAdmin = false;


    if (this.router.getCurrentNavigation()!.extras.state) { //occhio al non-null assertion operator, il punto esclamativo
      this.success = this.router.getCurrentNavigation()!.extras!.state!['success']; //occhio al non-null assertion operator, il punto esclamativo
      this.error = this.router.getCurrentNavigation()!.extras!.state!['error']; //occhio al non-null assertion operator, il punto esclamativo
      this.emailVerification = this.router.getCurrentNavigation()!.extras!.state!['emailVerification']; //occhio al non-null assertion operator, il punto esclamativo
    }
    //this.userSubscription = new Subscription;

    //this.user = this.authService.user;

    /*if(this.user_json){
      this.user = JSON.parse(this.user_json);
    }*/
  }

  async ngOnInit(): Promise<any> {

    const headers= this.authService.generateHeader();

    this.http.get(environment.API_URL+"profile", {headers})
      .subscribe({
        next: (res: any) => {
          this.user = res.user
          this.isAdmin = res.user.roles.includes('ADMIN');
          console.log(this.user)
        },
        error: (err: any) => {
          console.log("Error: profile", err)
          this.authService.errorManager(err);
        },
        complete: () => {
          this.loading = false;
        }
      })
  }

  ngOnDestroy(): void {

  }

  editProfile() {
    this.router.navigate(['/profile/edit-profile']);
  }

  deleteProfile(){
    this.message='';
    this.loading = true;

    const headers= this.authService.generateHeader();

    console.log("Delete called");
    this.http.delete(environment.API_URL+"profile", {headers})
    .subscribe({
      next: () => {


        localStorage.clear();
        this.router.navigate(['/login']);

        console.log("User deleted successfully");

      },
      error: (err: any) => {
        console.log("Error: ", err)
      },
      complete: () => {
        this.loading = false;

      }
    })
  }

  showDeleteConfirmModal(){
    this.message="Si è sicuri di cancellare il proprio profilo?"
  }

}
