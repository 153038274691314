import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { SidenavService } from './sidenav.service';
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

	public visible = true;
	message: string;
	isAdmin: boolean;
	user: any;

	constructor(
		private authService: AuthService,
		private router: Router,
		private http: HttpClient,
		private sidenavService: SidenavService,
	) {
		this.message = '';
		this.isAdmin = false;
	}

	ngOnInit(): void {

	this.sidenavService.setSidenav(this);

	if(this.authService.loggedIn()) {
		this.visible = (localStorage.getItem(environment.ACCESS_TOKEN_CODE + "_show_sidenav")) === 'true';
	}
	else {
		this.visible = false;
	}

	const headers= this.authService.generateHeader();

	this.http.get(environment.API_URL+"profile", {headers})
		.subscribe({
			next: (res: any) => {
			  this.user = res.user
			  this.isAdmin = res.user.roles.includes('ADMIN');
			  console.log(this.user)
		},
		error: (err: any) => {
			console.log("Error: ", err)
		},
		complete: () => {
		  //this.loading = false;
		}
	  })
	}

	show(){
		console.log("Showing sidebar");
	}

	hide(){

		console.log("Hiding sidebar");

	}

	toggle() {

		(this.visible) ? this.hide() : this.show();

		this.visible = !this.visible;

		localStorage.setItem(environment.ACCESS_TOKEN_CODE+"_show_sidenav", this.visible.toString());

	}
	/*
	isVisible() {
		return this.visible;
	}

	showLogoutConfirmModal(){
		this.message = "Si è sicuri di effettuare il logout?";
	}

	logout(){
		console.log("logout");
		this.authService.logoutUser();
	}
	*/
}
