import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as Leaflet from 'leaflet';

@Component({
  selector: 'app-home-map',
  templateUrl: './home-map.component.html',
  styleUrls: ['./home-map.component.scss']
})
export class HomeMapComponent implements OnInit, AfterViewInit {

	//private map: Leaflet.Map;
	//private centroid: Leaflet.LatLngExpression = [42.3601, -71.0589]; //

	private initMap(): void {

	let map: Leaflet.Map;
	let centroid: Leaflet.LatLngExpression = [42.194250, 13.395751]; //

		map = Leaflet.map('map', {
			center: centroid,
			zoom: 5.5
		});

		const tiles = Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 18,
			minZoom: 2,
			attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
		});



		tiles.addTo(map);


	}

  constructor() {
	}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
	  this.initMap();

  }

}
