<div *ngIf="loading" class="d-flex justify-content-center">
	<div  class="spinner-border text-primary loading-page-spinner" role="status">
		<span class="visually-hidden">Loading...</span>
	</div>
</div>

<app-confirm-modal [message]="message" *ngIf="message!=''" (close)="message = ''"></app-confirm-modal>

<app-add-day-or-lapse-modal *ngIf="showAddDayOrLapseModal" (addDayOrLapse)="addDayOrLapse($event)"  (close)="showAddDayOrLapseModal=false"></app-add-day-or-lapse-modal>

<div *ngIf="!loading" class="card scrollable">

	<div class="card-header fw-bolder">
		<h3>Nuova Sostituzione</h3>
	</div>

	<div class="card-body" style="height: 64vh;">
		<div class="row">
			<div class="col input-group mb-3">
				<span class="input-group-text">Tipo di sostituzione</span>
				<select class="form-select" aria-label="Type select" [(ngModel)]="newSubstitution.type" (ngModelChange)="checkForMMGorPLS()">
					<option selected value='' disabled>Seleziona un tipo</option>
					<option *ngFor="let substitutionType of substitutionTypes" [value]="substitutionType.id">
						{{substitutionType.name}}
					</option>
				</select>
			</div>
			<div class="col input-group mb-3">
				<span class="input-group-text">Inserisci a nome del medico: </span>
				<input class="form-control" list="datalistDoctors" id="exampleDataList" placeholder="Digita per cercare un medico..." [(ngModel)]="newSubstitution.requestingDoctorString" (ngModelChange)="refreshMiniDoctorsList()" >
				<datalist id="datalistDoctors">
					<option *ngFor="let doctor of miniDoctorsList">
						{{doctor.first_name}} {{doctor.last_name}} - {{doctor.fiscal_code}} - OdM {{doctor.provincia_ordine_medici}}
					</option>
				</datalist>
			</div>
		</div>

		<div class="text-danger" *ngIf="subTypeError!==''">{{this.subTypeError}}</div>

		<div class="row" *ngIf="newSubstitution.type!==''&&newSubstitution.requestingDoctorId!==null&&subTypeError===''">

			<div class="col">
				<div class="text-center mt-4 mb-5"><button class="btn btn-primary text-uppercase" (click)="showAddDayOrLapseModal=true">Aggiungi Giorno/Intervallo</button></div>

			</div>
			<div class="col">

				<div *ngIf="newSubstitution.dates.length>0" class="mb-3">
					<div class="card" *ngFor="let dateOrLapse of newSubstitution.dates; let i = index">
						<div *ngIf="dateOrLapse.secondDate===''" class="card-body row">
							<div class="col">
								Il giorno {{dateOrLapse.firstDate|date:'dd/MM/yyyy'}}
							</div>
							<div class="col-auto text-end">
								<i class="bi bi-x-circle remove-date-or-lapse" (click)="removeDateOrLapse(this.i)"></i>
							</div>
						</div>
						<div *ngIf="dateOrLapse.secondDate!==''" class="card-body row">
							<div class="col">
								Dal giorno {{dateOrLapse.firstDate|date:'dd/MM/yyyy'}} al giorno {{dateOrLapse.secondDate|date:'dd/MM/yyyy'}}
							</div>
							<div class="col-auto text-end">
								<i class="bi bi-x-circle remove-date-or-lapse" (click)="removeDateOrLapse(this.i)"></i>
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="newSubstitution.dates.length===0" class="text-center text-muted mt-4 mb-5">Inserisci un giorno o un intervallo di date</div>
			</div>
		</div>

		<div class="row" *ngIf="newSubstitution.type==='3'&&newSubstitution.requestingDoctorId!==null&&subTypeError===''">
			<h5 class="text-center">Orario:</h5>
		</div>

		<div class="row mb-5" *ngIf="newSubstitution.type==='3'&&newSubstitution.requestingDoctorId!==null&&subTypeError===''">
			<div class="col"></div>
			<div class="col input-group mb-3">
				<span class="input-group-text">Dalle:</span>
				<input type="time" class="form-control" [(ngModel)]="newSubstitution.subHourStart">
			</div>
			<div class="col input-group mb-3">
				<span class="input-group-text">Alle:</span>
				<input type="time" class="form-control" [(ngModel)]="newSubstitution.subHourEnd">
			</div>
			<div class="col"></div>
		</div>

		<div class="row" *ngIf="newSubstitution.type!==''&&newSubstitution.requestingDoctorId!==null&&subTypeError===''">
			<div class="col input-group mb-3">
				<span class="input-group-text">Altre informazioni</span>
				<input type="text" class="form-control"  [(ngModel)]="newSubstitution.otherInfo" placeholder="Facoltativamente inserisci qui altre informazioni (reperibilità, ecc.)">
			</div>
		</div>

		<div class="row" *ngIf="(newSubstitution.type==='1'||newSubstitution.type==='2')&&newSubstitution.requestingDoctorId!==null&&subTypeError===''">
			<div class="col input-group mb-3">
				<span class="input-group-text">Numero pazienti</span>
				<input type="number" min="1" class="form-control" [(ngModel)]="newSubstitution.patientsNumber">
			</div>

			<div class="col input-group mb-3">
				<span class="input-group-text">Programma di gestione</span>
				<input type="text" class="form-control" [(ngModel)]="newSubstitution.managementSoftware">
			</div>

			<div class="col input-group mb-3">
				<span class="input-group-text">Compenso giornaliero (in &euro;)</span>
				<input type="number" min="1" class="form-control" [(ngModel)]="newSubstitution.salary">
			</div>

		</div>

		<div class="row" *ngIf="(newSubstitution.type==='3'||newSubstitution.type==='4')&&newSubstitution.requestingDoctorId!==null&&subTypeError===''">
			<div class="col input-group mb-3">
				<span class="input-group-text">Indirizzo</span>
				<input type="text" class="form-control" [(ngModel)]="subAddress">
			</div>

			<div class="col input-group mb-3">
				<span class="input-group-text">Comune</span>
				<input type="text" class="form-control" [(ngModel)]="subCity">
			</div>

			<div class="col input-group mb-3">
				<span class="input-group-text">Provincia</span>
				<select class="form-select" aria-label="Province search select" [(ngModel)]="subProvince">
					<option selected value=''>Non selezionato</option>
					<option *ngFor="let province of provinces" [value]="province.code">
						{{province.name}}
					</option>
				</select>
			</div>

			<div class="col input-group mb-3">
				<span class="input-group-text">Compenso orario (in &euro;)</span>
				<input type="number" min="1" class="form-control" [(ngModel)]="newSubstitution.salary">
			</div>

		</div>

		<div class="row" *ngIf="(newSubstitution.type==='1'||newSubstitution.type==='2')&&newSubstitution.requestingDoctorId!==null&&subTypeError===''">
			<h5 class="text-center">Ambulatori:</h5>
			<div *ngFor="let clinic of clinics" class="col card">
				<div class="card-body">
					<p>{{clinic.address}}&nbsp;{{clinic.house_number}}, {{clinic.zip_code}}&nbsp;{{clinic.city}}&nbsp;({{clinic.province}})</p>
				</div>
			</div>
		</div>




	</div>


	<div class="card-footer">
		<div class="text-end me-3 mb-3"><button class="btn btn-primary text-uppercase" [disabled]="newSubstitution.dates.length<1||((newSubstitution.type=='1'||newSubstitution.type=='2')&&newSubstitution.patientsNumber===null)||newSubstitution.salary===null||this.subTypeError!==''||((newSubstitution.type=='3'||newSubstitution.type=='4')&&(subAddress==null||subCity==null||subProvince
		==null||subProvince=='')||(newSubstitution.type=='3'&&(newSubstitution.subHourStart==null||newSubstitution.subHourEnd==null)))" (click)="createSubstitutionRequest()">Inserisci nuova richiesta di Sostituzione</button></div>
	</div>

</div>
