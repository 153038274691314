<div *ngIf="success" class="alert alert-success alert-dismissible fade show" role="alert">
    ✔️ &nbsp;Profilo aggiornato con successo
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="success=false"></button>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
    ⚠️ &nbsp;C'è stato un errore durante l'aggiornamento del profilo
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="error=false"></button>
</div>

<div *ngIf="emailVerification" class="alert alert-success alert-dismissible fade show" role="alert">
    ✔️ &nbsp;È stata inviata un'email di conferma all'indirizzo specificato, fino a quel momento rimarrà valida la vecchia email
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="success=false"></button>
</div>

<div *ngIf="loading" class="d-flex justify-content-center">
    <div  class="spinner-border text-primary loading-page-spinner" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<app-confirm-modal [message]="message" *ngIf="message!=''" (close)="message = ''" (action)="deleteProfile()"></app-confirm-modal>

<div *ngIf="!loading" class="card">

    <div class="card-header">
        <xng-breadcrumb></xng-breadcrumb>

    </div>

    <div class="card-body">
        <div class="row">
            <div class="col text-center">

                <img src="/assets/icons/user.png" height="200">

            </div>
            <div class="col">
                <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div><b>First Name:</b>&nbsp;{{user.first_name}}</div>
                        
                    </div>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-start">
                        <div class="ms-2 me-auto">
                            <div><b>Last name:</b>&nbsp;{{user.last_name}}</div>
                        </div>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-start">
                        <div class="ms-2 me-auto">
                            <div><b>Username:</b>&nbsp;{{user.username}}</div>
                        </div>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-start">
                        <div class="ms-2 me-auto">
                            <div><b>Email:</b>&nbsp;{{user.email}}</div>
                        </div>
                    </li>

                    <li *ngIf="isAdmin" class="list-group-item d-flex justify-content-between align-items-start">
                        <div class="ms-2 me-auto">
                            <div><b>Roles:</b>&nbsp;{{user.roles}}</div>
                        </div>
                    </li>

                </ul>
            </div>
        
        </div>

        <!--
        <div class="row mt-5">


                <div class="col-2 border">Username:</div>
                <div class="col-4 border">{{user.username}}</div>
                <div class="col"><button class="btn btn-primary">Change username</button></div>

        </div>

        <div class="row mt-5">

                <div class="col-2 border">Email:</div>
                <div class="col-4 border">{{user.email}}</div>
                <div class="col"><button class="btn btn-primary">Change email</button></div>

        
        </div>
        -->
    </div>

    <div class="card-footer text-end">
        <!--<button class="btn btn-danger text-uppercase" (click)="showDeleteConfirmModal()">Delete Profile</button>-->
        <button class="btn btn-primary text-uppercase ms-2" (click)="editProfile()">Edit Profile</button>
    </div>

</div>