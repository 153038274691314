import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ActivatedRoute, Router } from '@angular/router';
import {AuthService} from "../../../auth.service";

@Component({
	selector: 'app-edit-user',
	templateUrl: './edit-user.component.html',
	styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

	user: any;

	roles: any;

	userRoles: any;

	selectedRoles: any;

	loading: boolean;

	showChangeUsernameModal: boolean;
	showChangeEmailModal: boolean;
	showChangePasswordModal: boolean;
	showDeleteProfileModal: boolean;
	message: string;

	constructor(
		private activatedRoute: ActivatedRoute,
		private authService: AuthService,
		private http: HttpClient,
		private router: Router
	) {

	this.user = null;

	this.roles = null;

	this.userRoles = [];

	this.selectedRoles = [];

	this.loading = true;

	this.showChangeUsernameModal=false;
	this.showChangeEmailModal = false;
	this.showChangePasswordModal = false;
	this.showDeleteProfileModal = false;
	this.message='';

	}

	async ngOnInit(): Promise<any> {

	this.loading = true;

	console.log("ID arrivato: ", this.activatedRoute.snapshot.paramMap.get('id'))

	const headers = this.authService.generateHeader();

	this.http.get(environment.API_URL+"users/"+this.activatedRoute.snapshot.paramMap.get('id'), {headers})
		.subscribe({
			next: (res: any) => {
				this.user = res

				this.user.roles.forEach( (role: any) => {

				this.userRoles.push(role.name)

				});

				console.log(this.user)

			},
		error: (err: any) => {
			console.log("Error: ", err)
		},
		complete: () => {

		this.http.get(environment.API_URL+"roles", {headers})
			.subscribe({
				next: (res: any) => {
					this.roles = res;

					this.roles.forEach( (role: any) => {

					if(this.userRoles.includes(role.name)){
					  role.enabled = true;
					}
					else{
					  role.enabled = false;
					}

					});


					console.log(this.roles);

				},
				error: (err: any) => {

				},
				complete: () => {
				  this.loading = false;

				}
				})

		}
		})
	}

	openChangeUsernameModal(): void {
		console.log("openChangeUsernameModal called");
		this.showChangeUsernameModal = true;
	}

	openChangeEmailModal(): void {
		console.log("openChangeEmailModal called");
		this.showChangeEmailModal = true;
	}

	openChangePasswordModal(): void {
		console.log("openChangePasswordModal called");
		this.showChangePasswordModal = true;
	}

	openDeleteProfileModal(): void {
		console.log("openDeleteProfileModal called");
		this.showDeleteProfileModal = true;
	}

	onSaveChanges() {

		this.message='';
		this.selectedRoles=[];
		this.loading = true;

		console.log("Updating user: ", this.user.id);

		const headers = this.authService.generateHeader();

		this.user.banned = this.user.banned ? 1:0; //Faccio il cast da boolean a int per evitare incoerenze con la struttura originale di user

		this.roles.forEach( (role: any) => {

			if(role.enabled === true){
			this.selectedRoles.push(role.name)
			}

		});

	const body = {
		'first_name': this.user.first_name,
		'last_name': this.user.last_name,
		'banned': this.user.banned,
		'roles': this.selectedRoles,
	}

	console.log("body:", body)

	this.http.put(environment.API_URL+"users/"+this.user.id, body, {headers})
		.subscribe({
			next: (res: any) => {
				this.loading = false;
				this.router.navigateByUrl('/users/view-user/'+this.user.id, { state: { success: true } });

				console.log("User updated successfully");
			},
		error: (err: any) => {

			this.loading = false;
			this.router.navigateByUrl('/users/view-user/'+this.user.id, { state: { error: true } });

			console.log("Update error: ", err)
		},
		complete: () => {


		}

	})

	/*
	console.log(body);
	this.loading = false;
	*/

	}

}
