import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-email-update',
  templateUrl: './email-update.component.html',
  styleUrls: ['./email-update.component.scss']
})
export class EmailUpdateComponent implements OnInit {

  token: string;

  loading: boolean;
  successfulResponse: boolean;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.token = '';
    this.successfulResponse = false;
    this.loading = true;
  }

  ngOnInit(): void {

    this.loading = true;

    this.route.params.subscribe(params=>{
      this.token = params['token'];
    })

    //Tento la chiamata senza headers TODO: da testare
    this.http.get(environment.API_URL+"email/update/"+this.token)
    .subscribe({
      next: (res: any) => {
        this.successfulResponse = true;
        //this.isAdmin = res.user.roles.includes('ADMIN');
        console.log(res)
        console.log("Test ok: ", res.ok);
        this.loading = false;

      },
      error: (err: any) => {

        this.successfulResponse = false;

        console.log("Error: ", err)
        console.log("Test ok: ", err.ok);

        this.loading = false;

      },
      complete: () => {

        this.loading = false;

      }
    })
  }

  onNavigateBackToHome() {
    localStorage.removeItem(environment.ACCESS_TOKEN_CODE);
    this.router.navigate(['/login']);
  }

}
