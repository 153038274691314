import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from "../../auth.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
	loading: boolean;
	users: any;
	currentPage: number;
	/*
		I had to add nextPage variable because there is this bug:
		If I had to display the previous page, I can use currentPage-1 and DOM correctly uses
		currentPage as a number, subtracts 1 from currentPage and displays the number as the result of the arithmetic operation
		but if, using the same logic, I had to display the next page and I use currentPage+1, DOM does not use
		currentPage as a number, but as a string, so displays the number 1 aside currentPage
		e.g.
		currentPage=5
		currentPage-1 is displayed correctly as 4
		currentPage+1 is displayed as 51, not as 6
	 */
	nextPage: number;
	lastPage: number;
	paginationItems: number;
	orderBy: string;
	orderDirection: string;
	resultsNumber: number;
	showFilters: boolean;

	// These are the variables that will contain values taken directly from the url
	urlParamId: string;
	urlParamFirstName: string;
	urlParamLastName: string;
	urlParamFiscalCode: string;
	urlParamEmail: string;
	urlParamVerifiedEmail: string;
	urlParamBanned: string;

	// These are the variables that will contain the current search values set, not necessarily applied
	searchId: string;
	searchFirstName: string;
	searchLastName: string;
	searchFiscalCode: string;
	searchEmail: string;
	searchVerifiedEmail: string;
	searchBanned: string;

	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private route: ActivatedRoute,
		private router: Router
	) {

		this.loading = true;

		this.users = null;

		this.currentPage = this.nextPage = this.lastPage = 1;

		this.paginationItems = environment.DEFAULT_PAGINATION_ITEMS;
		//Inizializzazione selettori d'ordinamento:
		this.orderBy = '';
		this.orderDirection = 'asc';

		this.resultsNumber = 0;

		this.showFilters = false;

		this.urlParamId = this.urlParamFirstName = this.urlParamLastName = this.urlParamFiscalCode = this.urlParamEmail = this.urlParamVerifiedEmail = this.urlParamBanned = '';

		this.searchId = this.searchFirstName = this.searchLastName = this.searchFiscalCode = this.searchEmail = this.searchVerifiedEmail = this.searchBanned = '';

	}

	ngOnInit() {

		this.loading = true;
		this.resultsNumber = 0;

		this.showFilters = (localStorage.getItem(environment.ACCESS_TOKEN_CODE+"_show_filters"))==='true';
		/*
		It's VERY important to maintain the second argument "|| environment.DEFAULT_PAGINATION_ITEMS"
		otherwise Angular returns an error due to the nullable nature of localStorage.getItem
		 */
		this.paginationItems = +(localStorage.getItem(environment.ACCESS_TOKEN_CODE+"_pagination_items")||environment.DEFAULT_PAGINATION_ITEMS);

		this.currentPage = 1;
		//Inizializzazione selettori d'ordinamento:
		this.orderBy = '';
		this.orderDirection = 'asc';

		const headers= this.authService.generateHeader();

		// Correctness check of search params
		let urlError = false;

		let url = environment.API_URL+"users?page="+this.currentPage+"&pagination="+this.paginationItems;

		this.route.queryParams
			.subscribe(params => {
				if(params['page']!==undefined){
					this.currentPage = params['page'];

					urlError = isNaN(Number(this.currentPage))||((+this.currentPage)<1);

				}
				if(params['order_by']!==undefined){
					this.orderBy = params['order_by'];

					urlError = urlError||(this.orderBy!=='id'&&this.orderBy!=='first_name'&&this.orderBy!=='last_name'&&this.orderBy!=='fiscal_code'&&this.orderBy!=='email'&&this.orderBy!=='verified_email'&&this.orderBy!=='banned');

				}

				if(params['order_direction']!==undefined){
					this.orderDirection = params['order_direction'];

					/*
					Edited to accept only asc or desc parameters, CAUTION: This only works if default this.orderDirection
					is set to 'asc' previously in ngOnInit()
					It's also a first control for errors in page, order_by and order_direction parameters
					*/
					if((this.orderDirection!=='asc'&&this.orderDirection!=='desc')){
						console.error('Url params not correctly formatted, resetting and reloading page...');
						this.orderBy='';
						this.onPaginatorClick(1);
					}

				}

				url = environment.API_URL+"users?page="+this.currentPage+"&pagination="+this.paginationItems;

				if(this.orderBy!==''&&this.orderBy!=undefined){
					url = url+"&order_by="+this.orderBy+"&order_direction="+this.orderDirection;
				}

				/*
				TODO: Da modificare leggermente, SE uno dei parametri è sbagliato, possiamo anche evitare di settare il nuovo valore per le variabili search
				 */

				if(params['id']!==undefined){
					this.urlParamId = this.searchId = params['id'];
					url = url+"&id="+this.searchId;
					urlError = urlError||isNaN(Number(this.urlParamId))||((+this.urlParamId)<1);
				}
				if(params['first_name']!==undefined){
					this.urlParamFirstName = this.searchFirstName = params['first_name'];
					url = url+"&first_name="+this.searchFirstName;
					urlError = urlError||this.urlParamFirstName.length>255;
				}
				if(params['last_name']!==undefined){
					this.urlParamLastName = this.searchLastName = params['last_name'];
					url = url+"&last_name="+this.searchLastName;
					urlError = urlError||this.urlParamLastName.length>255;
				}
				if(params['fiscal_code']!==undefined){
					this.urlParamFiscalCode = this.searchFiscalCode = params['fiscal_code'];
					url = url+"&fiscal_code="+this.searchFiscalCode;
					urlError = urlError||this.urlParamFiscalCode.length>255;
				}
				if(params['email']!==undefined){
					this.urlParamEmail = this.searchEmail = params['email'];
					url = url+"&email="+this.searchEmail;
					urlError = urlError||this.urlParamEmail.length>255;
				}
				if(params['verified_email']!==undefined){
					this.urlParamVerifiedEmail = params['verified_email'];
					this.urlParamVerifiedEmail==='true' ? this.searchVerifiedEmail = 'true' : this.searchVerifiedEmail = 'false';
					url = url+"&verified_email="+this.searchVerifiedEmail;
					urlError = urlError||(this.urlParamVerifiedEmail!=='true'&&this.urlParamVerifiedEmail!=='false');
				}
				if(params['banned']!==undefined){
					this.urlParamBanned = params['banned'];
					this.urlParamBanned==='true' ? this.searchBanned = 'true' : this.searchBanned = 'false';
					url = url+"&banned="+this.searchBanned;
					urlError = urlError||(this.urlParamBanned!=='true'&&this.urlParamBanned!=='false');
				}

				if(urlError){
					console.error('Url params not correctly formatted, resetting and reloading page...');
					this.orderBy='';
					this.onPaginatorClick(1);
				}

			})


		console.log("currentPage: ", this.currentPage);
		console.log("orderBy: ", this.orderBy);
		console.log("orderDirection: ", this.orderDirection);

		this.http.get(url, {headers})
			.subscribe({
				next: (res: any) => {

					console.log("Res: ", res);

					this.users = res.data;

					/*
					This is needed to reload page if someone tries to pass a page parameter superior at the last page value
					 */
					if(this.users.length===0&&this.currentPage>1){
						this.onPaginatorClick(1);
					}

					this.currentPage = parseInt(res.current_page);
					this.nextPage = this.currentPage+1;
					this.lastPage = parseInt(res.last_page);

					this.resultsNumber = parseInt(res.total);

					console.log(this.users)
				},
				error: (err: any) => {
					console.log("Error: ", err)
				},
				complete: () => {
					this.loading = false;
				}
			})
	}

	onViewUserClick(id: number){
		this.router.navigate(['users/view-user/'+id])
			.then(r => {
				//If the lazy loading fails, reload the page
				//TODO: Qui bisognerebbe mostrare un messaggio d'errore
				if(!r){
					window.location.reload();
				}
			});
	}
	onOrderByClick(parameter: string){
		//console.log("Order direction: ", this.orderDirection);
		if(this.orderDirection==='asc'&&(this.orderBy===''||this.orderBy==undefined)){
			this.orderBy=parameter;
		}
		else {
			if(this.orderBy!==parameter){
				this.orderBy=parameter;
				this.orderDirection='asc';
			}
			else if(this.orderDirection==='asc'){
				this.orderBy=parameter;
				this.orderDirection='desc';
			}
			else{
				this.orderBy='';
			}
		}

		this.onPaginatorClick(1);

	}

	onShowFiltersClick() {

		this.showFilters ? this.showFilters=false : this.showFilters=true;

		localStorage.setItem(environment.ACCESS_TOKEN_CODE+"_show_filters", this.showFilters.toString());

	}

	onKeydown(event: any) {
		if (event.key === "Enter") {
			this.onApplyFilters();
		}
	}

	onApplyFilters() {
		this.loading = true;

		let queryParams: any = {};

		queryParams.page = 1;

		if(this.searchId!==''){
			queryParams.id=this.searchId;
		}
		if(this.searchFirstName!==''){
			queryParams.first_name=this.searchFirstName;
		}
		if(this.searchLastName!==''){
			queryParams.last_name=this.searchLastName;
		}
		if(this.searchFiscalCode!==''){
			queryParams.fiscal_code=this.searchFiscalCode;
		}
		if(this.searchEmail!==''){
			queryParams.email=this.searchEmail;
		}

		if(this.searchVerifiedEmail!==''){
			queryParams.verified_email=this.searchVerifiedEmail;
		}

		if(this.searchBanned!==''){
			queryParams.banned=this.searchBanned;
		}



		this.router.navigate(['/users'], {queryParams: queryParams})
			.then(r => {
				/*
					If the lazy loading is successful, call ngOnInit, if not, force page reloading
				 */
				//r ? this.ngOnInit() : window.location.reload();
				//TODO: Qui bisognerebbe usare il lazy loading
				window.location.reload();
			});
	}

	removeFilter(filter: string){
		switch (filter){
			case 'id':
				this.searchId='';
				break;
			case 'firstName':
				this.searchFirstName='';
				break;
			case 'lastName':
				this.searchLastName='';
				break;
			case 'fiscal_code':
				this.searchFiscalCode='';
				break;
			case 'email':
				this.searchEmail='';
				break;
			case 'verifiedEmail':
				this.searchVerifiedEmail='';
				break;
			case 'banned':
				this.searchBanned='';
				break;
			default:
				break;
		}
		this.onApplyFilters();
	}

	onPaginationItemsChange() {

		localStorage.setItem(environment.ACCESS_TOKEN_CODE+"_pagination_items", this.paginationItems.toString());

		this.currentPage==1 ? this.ngOnInit() : this.onPaginatorClick(1);

	}

	onPaginatorClick(page: number) {

		this.loading = true;

		let queryParams: any = {};

		queryParams.page = page;

		if(this.orderBy!==''){
			queryParams.order_by=this.orderBy
			queryParams.order_direction=this.orderDirection //Default is asc
		}

		if(this.urlParamId!==''){
			queryParams.id=this.urlParamId;
		}
		if(this.urlParamFirstName!==''){
			queryParams.first_name=this.urlParamFirstName;
		}
		if(this.urlParamLastName!==''){
			queryParams.last_name=this.urlParamLastName;
		}
		if(this.urlParamFiscalCode!==''){
			queryParams.fiscal_code=this.urlParamFiscalCode;
		}
		if(this.urlParamEmail!==''){
			queryParams.email=this.urlParamEmail;
		}

		if(this.urlParamVerifiedEmail!==''){
			queryParams.verified_email=this.urlParamVerifiedEmail;
		}

		if(this.urlParamBanned!==''){
			queryParams.banned=this.urlParamBanned;
		}



		this.router.navigate(['/users'], {queryParams: queryParams})
			.then(r => {
				/*
					If the lazy loading is successful, call ngOnInit, if not, force page reloading
				 */
				r ? this.ngOnInit() : window.location.reload();
			});
	}

}
