<div class="card scrollable">

	<div class="card-header ps-0 pe-0">
		<div class="search-collapse col">
			<div class="row p-1">
				<div class="col-auto ms-3" (click)="onShowFiltersClick()" data-bs-toggle="collapse" href="#collapseFilters" role="button" aria-expanded="false" aria-controls="collapseFilters">
					<i class="bi bi-search"></i><i class="bi bi-caret-down-fill"></i>
				</div>
				<div class="col" *ngIf="urlParamId===''&&urlParamFirstName===''&&urlParamLastName===''&&urlParamFiscalCode===''&&urlParamEmail===''&&urlParamVerifiedEmail===''&&urlParamBanned===''">
					Nessun filtro applicato
				</div>
				<div class="col-auto" *ngIf="urlParamId!==''||urlParamFirstName!==''||urlParamLastName!==''||urlParamFiscalCode!==''||urlParamEmail!==''||urlParamVerifiedEmail!==''||urlParamBanned!==''">
					Filtri applicati:
				</div>

				<div class="col-auto" *ngIf="urlParamId!==''">
					<span class="badge bg-success">Id
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('id')"></i>
					</span>
				</div>
				<div class="col-auto" *ngIf="urlParamFirstName!==''">
					<span class="badge bg-success">Nome
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('firstName')"></i>
					</span>
				</div>
				<div class="col-auto" *ngIf="urlParamLastName!==''">
					<span class="badge bg-success">Cognome
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('lastName')"></i>
					</span>
				</div>
				<div class="col-auto" *ngIf="urlParamFiscalCode!==''">
					<span class="badge bg-success">Codice Fiscale
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('FiscalCode')"></i>
					</span>
				</div>
				<div class="col-auto" *ngIf="urlParamEmail!==''">
					<span class="badge bg-success">Email
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('email')"></i>
					</span>
				</div>
				<div class="col-auto" *ngIf="urlParamVerifiedEmail!==''">
					<span class="badge bg-success">Email Verificata
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('verifiedEmail')"></i>
					</span>
				</div>
				<div class="col-auto" *ngIf="urlParamBanned!==''">
					<span class="badge bg-success">Bannato
						<i class="bi bi-x-circle remove-filter" (click)="removeFilter('banned')"></i>
					</span>
				</div>

				<div class="col">
				</div>
				<div *ngIf="!loading" class="col text-end me-5">
					Numero di utenti trovati: {{resultsNumber}}
				</div>

			</div>
			<div class="row collapse mt-1 ps-4" [ngClass]="{'show': (showFilters)}"  style="height: 11vh;" id="collapseFilters">
				<div class="row">
					<div class="col input-group mb-3" style="max-width: 20vh">
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchId!==urlParamId && searchId!=='')||(urlParamId!==''&&searchId===''), 'bg-success': urlParamId!=='' && searchId!=='' , 'text-white': ((searchId!==urlParamId && searchId!=='')||(urlParamId!==''&&searchId===''))||(urlParamId!==''&&searchId!=='')}">Id</span>
						<input type="text" (keydown)="onKeydown($event)" class="form-control" [(ngModel)]="searchId">
					</div>
					<div class="col input-group mb-3">
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchFirstName!==urlParamFirstName && searchFirstName!=='')||(urlParamFirstName!==''&&searchFirstName===''), 'bg-success': urlParamFirstName!=='' && searchFirstName!=='' , 'text-white': ((searchFirstName!==urlParamFirstName && searchFirstName!=='')||(urlParamFirstName!==''&&searchFirstName===''))||(urlParamFirstName!==''&&searchFirstName!=='')}">Nome</span>
						<input type="text" (keydown)="onKeydown($event)" class="form-control" [(ngModel)]="searchFirstName">
					</div>
					<div class="col input-group mb-3">
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchLastName!==urlParamLastName && searchLastName!=='')||(urlParamLastName!==''&&searchLastName===''), 'bg-success': urlParamLastName!=='' && searchLastName!=='' , 'text-white': ((searchLastName!==urlParamLastName && searchLastName!=='')||(urlParamLastName!==''&&searchLastName===''))||(urlParamLastName!==''&&searchLastName!=='')}">Cognome</span>
						<input type="text" (keydown)="onKeydown($event)" class="form-control" [(ngModel)]="searchLastName">
					</div>
					<div class="col input-group mb-3" >
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchFiscalCode!==urlParamFiscalCode && searchFiscalCode!=='')||(urlParamFiscalCode!==''&&searchFiscalCode===''), 'bg-success': urlParamFiscalCode!=='' && searchFiscalCode!=='' , 'text-white': ((searchFiscalCode!==urlParamFiscalCode && searchFiscalCode!=='')||(urlParamFiscalCode!==''&&searchFiscalCode===''))||(urlParamFiscalCode!==''&&searchFiscalCode!=='')}">Codice Fiscale</span>
						<input type="text" (keydown)="onKeydown($event)" class="form-control" [(ngModel)]="searchFiscalCode">
					</div>
				</div>
				<div class="row">

					<div class="col input-group mb-3">
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchEmail!==urlParamEmail && searchEmail!=='')||(urlParamEmail!==''&&searchEmail===''), 'bg-success': urlParamEmail!=='' && searchEmail!=='' , 'text-white': ((searchEmail!==urlParamEmail && searchEmail!=='')||(urlParamEmail!==''&&searchEmail===''))||(urlParamEmail!==''&&searchEmail!=='')}">Email</span>
						<input type="text" (keydown)="onKeydown($event)" class="form-control" [(ngModel)]="searchEmail">
					</div>



					<div class="col input-group mb-3">
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchVerifiedEmail!==urlParamVerifiedEmail && searchVerifiedEmail!=='')||(urlParamVerifiedEmail!==''&&searchVerifiedEmail===''), 'bg-success': urlParamVerifiedEmail!=='' && searchVerifiedEmail!=='' , 'text-white': ((searchVerifiedEmail!==urlParamVerifiedEmail && searchVerifiedEmail!=='')||(urlParamVerifiedEmail!==''&&searchVerifiedEmail===''))||(urlParamVerifiedEmail!==''&&searchVerifiedEmail!=='')}">Email Verificata</span>
						<select (keydown)="onKeydown($event)" class="form-select" aria-label="Verified email search select" [(ngModel)]="searchVerifiedEmail">
							<option selected value=''>Non selezionato</option>
							<option value='true'>Sì</option>
							<option value='false'>No</option>
						</select>
						<!--<div class="col form-check form-switch mt-2 ms-2">

							<input class="form-check-input" type="checkbox" id="flexSwitchVerifiedEmail" [(ngModel)]="searchVerifiedEmail">
						</div>
						-->
					</div>

					<div class="col input-group mb-3">
						<span class="input-group-text" [ngClass]="{'bg-warning': (searchBanned!==urlParamBanned && searchBanned!=='')||(urlParamBanned!==''&&searchBanned===''), 'bg-success': urlParamBanned!=='' && searchBanned!=='' , 'text-white': ((searchBanned!==urlParamBanned && searchBanned!=='')||(urlParamBanned!==''&&searchBanned===''))||(urlParamBanned!==''&&searchBanned!=='')}">Bannato</span>
						<select (keydown)="onKeydown($event)" class="form-select" aria-label="Banned search select" [(ngModel)]="searchBanned">
							<option selected value=''>Non selezionato</option>
							<option value='true'>Sì</option>
							<option value='false'>No</option>
						</select>
					</div>

					<div class="col text-end"><button class="btn btn-primary text-uppercase" (click)="onApplyFilters()">Applica Filtri</button></div>

				</div>
			</div>
		</div>
    </div>

	<div class="card-body p-0" [style.height]="showFilters? '64vh' : '76vh'">

        <div *ngIf="loading" class="d-flex justify-content-center">
            <div  class="spinner-border text-primary loading-page-spinner" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

		<div *ngIf="!loading&&users.length==0" class="d-flex justify-content-center">
			<div  class="text-primary mt-5">
				Nessun utente trovato con i filtri selezionati
			</div>
		</div>

        <div class="table-responsive" style="overflow: auto; height: 100%;">
            <table *ngIf="!loading" class="table table-bordered table-responsive">
                <thead>
                    <tr>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='id')}" (click)="onOrderByClick('id')">
							<i *ngIf="orderBy!='id'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='id'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='id'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Id
						</th>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='first_name')}" (click)="onOrderByClick('first_name')">
							<i *ngIf="orderBy!='first_name'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='first_name'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='first_name'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Nome
						</th>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='last_name')}" (click)="onOrderByClick('last_name')">
							<i *ngIf="orderBy!='last_name'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='last_name'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='last_name'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Cognome
						</th>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='fiscal_code')}" (click)="onOrderByClick('fiscal_code')">
							<i *ngIf="orderBy!='fiscal_code'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='fiscal_code'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='fiscal_code'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Codice Fiscale
						</th>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='email')}" (click)="onOrderByClick('email')">
							<i *ngIf="orderBy!='email'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='email'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='email'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Email
						</th>
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='email_verified_at')}" (click)="onOrderByClick('email_verified_at')">
							<i *ngIf="orderBy!='email_verified_at'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='email_verified_at'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='email_verified_at'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Email Verificata
						</th>
						<th scope="col">Codice OdM Verificato</th>
						<!--
						<th class="column-header" scope="col" [ngClass]="{'bg-success text-white': (orderBy==='banned')}" (click)="onOrderByClick('banned')">
							<i *ngIf="orderBy!='banned'||orderBy==undefined" class="bi bi-list-ul"></i>
							<i *ngIf="orderBy=='banned'&&orderDirection=='asc'" class="bi bi-sort-down"></i>
							<i *ngIf="orderBy=='banned'&&orderDirection=='desc'" class="bi bi-sort-up"></i>
							&nbsp;Bannato
						</th>
						-->
						<th scope="col">Azioni</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let user of users">
                        <th scope="row">{{user.id}}</th>
                        <td>{{user.first_name}}</td>
                        <td>{{user.last_name}}</td>
                        <td>{{user.fiscal_code}}</td>
                        <td>{{user.email}}</td>
                        <td>
                            <div *ngIf="!user.email_verified_at" class="text-danger fw-bold">No</div>
                            <div *ngIf="user.email_verified_at">Sì</div>
                        </td>
                        <td>
                          <div *ngIf="!user.verified_codice_ordine_medici" class="text-danger fw-bold">No</div>
                          <div *ngIf="user.verified_codice_ordine_medici">Sì</div>
                        </td>
						<!--
                        <td>
                            <div *ngIf="user.banned">🛑</div>
                        </td>
                        -->
                        <td>

                            <button class="btn btn-primary text-uppercase" (click)="onViewUserClick(user.id)">Visualizza</button>
                            <!--
                            <button class="btn btn-primary text-uppercase ms-3">Edit User</button>
                            -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
	<div class="card-footer" style="height: 8vh">
		<div class="row w-100">
			<!--GESTIONE PAGINAZIONE-->
			<div class="col d-flex">
				<div class="mt-1 ms-5">Elementi per pagina:&nbsp;</div>
				<select class="form-select" style="max-width: 100px; max-height: 35px;" [(ngModel)]="paginationItems" (ngModelChange)="onPaginationItemsChange()">
					<option value="10">10</option>
					<option value="20">20</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>
			</div>

			<nav *ngIf="!loading" class="col-auto" aria-label="Page navigation">
				<ul class="pagination">
					<li *ngIf="currentPage>1" class="page-item me-3" (click)='onPaginatorClick(currentPage-1)'><a class="page-link" > < </a></li> <!--Deve scomparire se siamo a pagina 1-->
					<li class="page-item" [ngClass]="{'active': (currentPage===1)}"><a class="page-link" (click)='onPaginatorClick(1)'>1</a></li> <!--Pagina 1 la lasciamo SEMPRE visibile-->
					<li *ngIf="currentPage>3" class="page-item"><a class="page-link" (click)='onPaginatorClick(currentPage-2)'>...</a></li> <!--Deve comparire se la pagina è > 3-->

					<li *ngIf="currentPage<=3 && lastPage>1" class="page-item" [ngClass]="{'active': (currentPage===2)}" (click)='onPaginatorClick(2)'><a class="page-link">2</a></li>
					<li *ngIf="currentPage>3" class="page-item"><a class="page-link" (click)='onPaginatorClick(currentPage-1)'>{{(currentPage-1)}}</a></li>

					<li *ngIf="currentPage<=3 && lastPage>2" [ngClass]="{'active': (currentPage===3)}" class="page-item" (click)='onPaginatorClick(3)'><a class="page-link">3</a></li>
					<li *ngIf="currentPage>3 && currentPage!==lastPage" class="page-item active"><a class="page-link">{{currentPage}}</a></li>

					<li *ngIf="currentPage<=3 && lastPage>3 && lastPage!==4" class="page-item" (click)='onPaginatorClick(4)'><a class="page-link" >4</a></li>
					<li *ngIf="currentPage>3 && currentPage!==(lastPage-1) && currentPage!==lastPage" class="page-item"><a class="page-link" (click)='onPaginatorClick(currentPage+1)'>{{nextPage}}</a></li>

					<li *ngIf="currentPage<=(lastPage-3) && lastPage!==4" class="page-item"><a class="page-link" (click)='onPaginatorClick(currentPage+2)'>...</a></li> <!--Deve comparire se manca una pagina tra quelle visualizzate e l'ultima-->
					<li *ngIf="lastPage>3" class="page-item" [ngClass]="{'active': (currentPage===lastPage)}"><a class="page-link" (click)='onPaginatorClick(lastPage)'>{{lastPage}}</a></li> <!--Deve comparire se le pagine sono più di 3-->
					<li *ngIf="currentPage!==lastPage" class="page-item ms-3" (click)='onPaginatorClick(currentPage+1)'><a class="page-link"> > </a></li> <!--Deve scomparire se siamo all'ultima pagina-->
				</ul>
			</nav>

			<div class="col text-end"><button routerLink="/users/create-user" class="btn btn-primary text-uppercase">Crea utente</button></div>
		</div>
	</div>

</div>
