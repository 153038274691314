import { Component, Input, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  constructor(
    public router : Router,
    private route : ActivatedRoute
  ){}

  @Input() message: any;
  @Output() close = new EventEmitter<void>();
  @Output() action = new EventEmitter<boolean>()

  onCancelClick() {
    this.close.emit();
  }

  onConfirmClick() {
    this.action.emit();
  }


}
