import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin.guard';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { EditProfileComponent } from './home/profile/edit-profile/edit-profile.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './home/profile/profile.component';
import { UsersComponent } from './home/users/users.component';
import { ProfileLayoutComponent } from './layouts/main-layout/profile-layout/profile-layout.component';
import { UsersLayoutComponent } from './layouts/main-layout/users-layout/users-layout.component';
import { EmailUpdateComponent } from './email-update/email-update.component';
import { ViewUserComponent } from './home/users/view-user/view-user.component';
import { EditUserComponent } from './home/users/edit-user/edit-user.component';
import {SubstitutionsLayoutComponent} from "./layouts/main-layout/substitutions-layout/substitutions-layout.component";
import {SubstitutionsComponent} from "./home/substitutions/substitutions.component";
import {ViewSubstitutionComponent} from "./home/substitutions/view-substitution/view-substitution.component";
import {NewSubstitutionComponent} from "./home/substitutions/new-substitution/new-substitution.component";
import {CreateUserComponent} from "./home/users/create-user/create-user.component";

const routes: Routes = [
  {
    path: 'login',
    component: AuthLayoutComponent,

    children: [
      {
        path: '',
        component: LoginComponent,

      }
    ]
  },

  {
    path: 'register',
    component: AuthLayoutComponent,

    children: [
      {
        path: '',
        component: RegisterComponent,

      }
    ]
  },

  {
    path: 'email-update/:token',
    component: AuthLayoutComponent,

    children: [
      {
        path: '',
        component: EmailUpdateComponent,

      }
    ]
  },

  {
    path: '',
    component: MainLayoutComponent,

    canActivate:[AuthGuard],
    data: {
      breadcrumb: {
        label: 'Home',
        alias: 'Home'
      }
    },
    children: [
      {
        path: '',
		  canActivate:[AuthGuard],
		  component: HomeComponent,
      },
      {
        path: 'profile',
        component: ProfileLayoutComponent,
        data: {
          breadcrumb: 'Profilo'
        },

        children: [
          {
            path: '',
            component: ProfileComponent,
          },
          {
            path: 'edit-profile',
            component: EditProfileComponent,
            data: {
              breadcrumb: 'Modifica Profilo'
            },
          }
      ]
      },
      {
        path: 'users',
        component: UsersLayoutComponent,
        canActivate:[AdminGuard],
        data: {
          breadcrumb: 'Utenti'
        },
        children: [
          {
            path: '',
            component: UsersComponent,
          },
			{
			path: 'create-user',
			component: CreateUserComponent,
			data: {
				breadcrumb: 'Crea nuovo utente'
				}
			},
          {
            path: 'view-user/:id',
            component: ViewUserComponent,
            data: {
              breadcrumb: 'Visualizza Utente'
            },
          },
          {
            path: 'edit-user/:id',
            component: EditUserComponent,
            data: {
              breadcrumb: 'Modifica Utente'
            },
          },

        ]
      },
		{
			path: 'substitutions',
			component: SubstitutionsLayoutComponent,
			canActivate:[AuthGuard],
			data: {
				breadcrumb: 'Sostituzioni'
			},
			children: [
				{
					path: '',
					canActivate:[AuthGuard],
					component: SubstitutionsComponent,
				},

				{
					path: 'new-substitution',
					component: NewSubstitutionComponent,
					canActivate: [AdminGuard],
					data: {
						breadcrumb: 'Nuova Sostituzione'
					},
				},

				{
					path: 'view-substitution/:id',
					component: ViewSubstitutionComponent,
					data: {
						breadcrumb: 'Dettagli Sostituzione'
					},
				},
				/*
				{
					path: 'edit-user/:id',
					component: EditUserComponent,
					data: {
						breadcrumb: 'Edit User'
					},
				},
				*/
			]
		},
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
