<div class="backdrop"></div>
<div class="alert-box">
    <div class="mb-3">
        <label for="EmailEditInput" class="form-label">Immettere nuova email:</label>
        <input [(ngModel)]="email" type="text" class="form-control" id="EmailEditInput" placeholder="Non può essere vuoto">
        <div *ngIf="loading" class="d-flex justify-content-center">
            <div  class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <small *ngIf="errorMessage" class="text-danger">{{errorMessage}}</small>
    </div>
    <div class="alert-box-actions mt-3 text-end">
        
        <button type="button" class="btn btn-danger" (click)="onCancelClick()">Cancel</button>

        <button type="button" class="btn btn-primary ms-3 text-right" (click)="onConfirmClick()">Ok</button>

    </div>
</div>