import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SidenavService } from '../sidenav/sidenav.service';

import { AuthService } from '../../auth.service';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

	app_name = environment.APP_NAME;
	user: any;
	name = '';
	message: string;
	isAdmin: boolean;
	isLoggedIn: boolean;

	constructor(
		private authService: AuthService,
		private sidenav: SidenavService,
		private http: HttpClient,
	) {
		this.name = '';
		this.message = '';
		this.isAdmin = false;
		this.isLoggedIn = this.authService.loggedIn();

	}

  	toggleActive:boolean = false;

	ngOnInit(): void {
	//this.user = this.authService.user;
	const headers= this.authService.generateHeader();

		this.isLoggedIn = this.authService.loggedIn();

		this.http.get(environment.API_URL+"profile", {headers})
		.subscribe({
			next: (res: any) => {
				this.user = res.user
				this.isAdmin = res.user.roles.includes('ADMIN');
				console.log(this.user)

			},
			error: (err: any) => {
				console.log("Error: ", err)
			},
			complete: () => {
				this.name = this.user.first_name + " " + this.user.last_name;
			}
		})
	}

	toggleSidenav() {
		this.toggleActive = !this.toggleActive;
		this.sidenav.toggle();
		console.log('Clicked');
	}

	showLogoutConfirmModal(){
		this.message = "Si è sicuri di effettuare il logout?";
	}

	logout(){
		console.log("logout");
		this.authService.logoutUser();
	}

}
