<div>

  <app-alert-modal [message]="message" *ngIf="message!=''" (close)="message = ''"></app-alert-modal>

  <div class="form-login d-flex flex-column">

    <div class="text-center mt-2">
      <img class="mb-2 mt-5" width="200" src="assets/images/logo.png">
    </div>

    <div class="text-center mb-4 text-white">
      <h2>BACKOFFICE</h2>
    </div>

    <div class="card">

      <!--
      <div class="card-header text-center">
        LOGIN
      </div>
      -->

      <div class="card-body">

        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

          <div class="form-group m-3">

            <label class="center-block">
              Email:
            </label>

            <input class="form-control" formControlName="email" placeholder="Email" required>

          </div>

          <div *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty && loginForm.controls['email'].touched)" class="m-3 alert alert-warning">

              Please insert a valid email

          </div>

          <div class="form-group m-3">

            <label class="center-block">
              Password:
            </label>

            <input class="form-control" formControlName="password" type="password" placeholder="Password" required>

          </div>

          <div *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty && loginForm.controls['password'].touched)" class="m-3 alert alert-warning">

              Please insert a valid password

          </div>

          <div class="text-center">
          <button class="m-3 btn btn-primary w-50" color="primary" type="submit" [disabled]="!loginForm.valid">LOGIN</button>
          </div>
        </form>

        <!--
        <a href="/register">New user? Sign up</a>
        -->
        <div *ngIf="loading" class="d-flex justify-content-center mt-3">
          <div  class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
          </div>
        </div>

      </div>

    </div>

  </div>

</div>
