<div class="backdrop"></div>
<div class="alert-box">

	<div class="row">
		<div class="col input-group mb-3">
			<span *ngIf="!addInterval" class="input-group-text">Seleziona una data:</span>
			<span *ngIf="addInterval" class="input-group-text">Seleziona una data di inizio:</span>
			<input type="date" class="form-control" #selectedFirstDate [(ngModel)]="dayOrLapse.firstDate"/>
		</div>
	</div>

	<div class="form-check form-switch">
		<input class="form-check-input" type="checkbox" id="flexAddIntervalSwitchCheck" [(ngModel)]="addInterval" (click)="dayOrLapse.secondDate=''">
		<label class="form-check-label" for="flexAddIntervalSwitchCheck">Aggiungi intervallo di date</label>
	</div>

	<div class="row mt-3" *ngIf="addInterval">
		<div class="col input-group mb-3">
			<span class="input-group-text" [ngClass]="{'bg-danger': ((dayOrLapse.firstDate!=''&&dayOrLapse.secondDate!=''&&addInterval)&&compareDates()), 'text-white': ((dayOrLapse.firstDate!=''&&dayOrLapse.secondDate!=''&&addInterval)&&compareDates())}">Seleziona una data di termine:</span>
			<input type="date" class="form-control" #selectedSecondDate [(ngModel)]="dayOrLapse.secondDate"/>
		</div>
	</div>

	<p class="text-danger mt-2" *ngIf="(dayOrLapse.firstDate!=''&&dayOrLapse.secondDate!=''&&addInterval)&&compareDates()">
		Attenzione! Se si intende selezionare un intervallo di date,<br>la data di termine non può nè coincidere con la data di inizio nè essere precedente alla data di inizio.
	</p>


	<div class="alert-box-actions mt-5 text-end">

		<button type="button" class="btn btn-danger" (click)="onCancelClick()">Annulla</button>

		<button type="button" class="btn btn-primary ms-3 text-right" [disabled]="(dayOrLapse.firstDate=='')||(addInterval&&dayOrLapse.secondDate=='')||compareDates()" (click)="onAddDayOrIntervalClick()">Aggiungi</button>

	</div>
</div>
