<div *ngIf="loading" class="d-flex justify-content-center">
    <div  class="spinner-border text-primary loading-page-spinner" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<app-change-username-modal [username]="user.username" [id]="user.id" *ngIf="showChangeUsernameModal" (close)="showChangeUsernameModal = false"></app-change-username-modal>

<app-change-email-modal [email]="user.email" [id]="user.id" *ngIf="showChangeEmailModal" (close)="showChangeEmailModal = false"></app-change-email-modal>

<app-change-password-modal [id]="user.id" *ngIf="showChangePasswordModal" (close)="showChangePasswordModal = false"></app-change-password-modal>

<div *ngIf="!loading" class="card scrollable">

    <div class="card-header">
        <xng-breadcrumb></xng-breadcrumb>

    </div>

    <div class="card-body" style="height: 100vh;">
        <div class="row">
            <div class="col text-center">

                <img src="/assets/icons/user.png" height="200">

            </div>
            <div class="col">

                <div class="mb-3 d-flex">
                    <label for="firstNameEditInput" class="form-label">Nome:</label>
                    <input [(ngModel)]="user.first_name" type="text" class="form-control" id="firstNameEditInput" placeholder="Non può essere vuoto">
                </div>


                <div class="mb-3 d-flex">
                    <label for="lastNameEditInput" class="form-label">Cognome:</label>
                    <input [(ngModel)]="user.last_name" type="text" class="form-control" id="lastNameEditInput" placeholder="Non può essere vuoto">
                </div>

                <div class="mb-3 d-flex">
                  <label for="fiscalCodeEditInput" class="form-label">Codice Fiscale:</label>
                  <input [(ngModel)]="user.fiscal_code" type="text" class="form-control" id="fiscalCodeEditInput" placeholder="Non può essere vuoto">
                </div>

                <div class="mb-3 d-flex">
                  <label for="codiceOrdineMediciEditInput" class="form-label">Codice Ordine dei Medici:</label>
                  <input [(ngModel)]="user.codice_ordine_medici" type="text" class="form-control" id="codiceOrdineMediciEditInput" placeholder="Non può essere vuoto">
                </div>

                <div class="mb-3 d-flex">
                  <label for="provinciaOrdineMediciEditInput" class="form-label">Provincia Ordine dei Medici:</label>
                  <input [(ngModel)]="user.provincia_ordine_medici" type="text" class="form-control" id="provinciaOrdineMediciEditInput" placeholder="Non può essere vuoto">
                </div>

                <div class="mb-3 d-flex">
                  <a href="https://portale.fnomceo.it/cerca-prof/index.php">FNOMCeO Anagrafica per ricerca Codici OdM</a>
                </div>



                <div class="form-check form-switch">
                  <label class="form-check-label" for="flexSwitchCodiceOdM">Codice OdM Verificato</label>
                  <input [(ngModel)]="user.verified_codice_ordine_medici" class="form-check-input banned-check" type="checkbox" id="flexSwitchCodiceOdM">
                </div>

                <div class="form-check form-switch">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Bannato</label>
                    <input [(ngModel)]="user.banned" class="form-check-input banned-check" type="checkbox" id="flexSwitchCheckDefault">
                </div>
                <!--
                <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div><b>First Name:</b>&nbsp;{{user.first_name}}</div>

                    </div>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-start">
                        <div class="ms-2 me-auto">
                            <div><b>Last name:</b>&nbsp;{{user.last_name}}</div>
                        </div>
                    </li>

                </ul>
            -->
            </div>

        </div>

		<!--
        <div class="row mt-2">
          Ruoli:
          <div class="table-responsive" style="overflow: auto">
                <table *ngIf="!loading" class="table table-bordered table-responsive">
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Descrizione</th>
                            <th scope="col">Abilitata</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let role of roles">
                            <th scope="row">{{role.name}}</th>
                            <td>{{role.description}}</td>
                            <td>
                                <div class="form-check form-switch">
                                    <input *ngIf="role.name!=='USER'" [(ngModel)]="role.enabled"  class="form-check-input"  type="checkbox">
                                    <input *ngIf="role.name==='USER'" [(ngModel)]="role.enabled" class="form-check-input" type="checkbox" disabled>
                                </div>
                            </td>


                        </tr>
                    </tbody>

                </table>
            </div>

        </div>

		-->

        <div class="row mt-2">

            <div class="col-2 border">Email:</div>
            <div class="col-4 border">{{user.email}}</div>
            <div class="col"><button class="btn btn-primary" (click)="openChangeEmailModal()">Modifica Email</button></div>

        </div>

        <div class="row mt-2">

            <div class="col-2"></div>
            <div class="col-4"></div>
            <div class="col"><button class="btn btn-primary" (click)="openChangePasswordModal()">Modifica Password</button></div>

        </div>
    </div>

    <div class="card-footer text-end">
        <button class="btn btn-warning text-uppercase" routerLink="/users">Annulla</button>
        <button class="btn btn-success text-uppercase ms-2" (click)="onSaveChanges()">Salva Modifiche</button>
    </div>

</div>
