import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Router } from '@angular/router';
import {AuthService} from "../../../auth.service";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  user: any;
  loading: boolean;
  showChangeUsernameModal: boolean;
  showChangeEmailModal: boolean;
  showChangePasswordModal: boolean;
  showDeleteProfileModal: boolean;
  message: string;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private router: Router
  )
  {

    this.loading=true;
    this.showChangeUsernameModal=false;
    this.showChangeEmailModal = false;
    this.showChangePasswordModal = false;
    this.showDeleteProfileModal = false;
    this.message='';

  }

  async ngOnInit(): Promise<any> {

    const headers= this.authService.generateHeader();

    this.http.get(environment.API_URL+"profile", {headers})
      .subscribe({
        next: (res: any) => {
          this.user = res.user
          //this.isAdmin = res.user.roles.includes('ADMIN');
          console.log(this.user)
        },
        error: (err: any) => {
          console.log("Error: ", err)
        },
        complete: () => {


          this.loading = false;
        }
      })
  }

  openChangeUsernameModal(): void {
    console.log("openChangeUsernameModal called");
    this.showChangeUsernameModal = true;
  }

  openChangeEmailModal(): void {
    console.log("openChangeEmailModal called");
    this.showChangeEmailModal = true;
  }

  openChangePasswordModal(): void {
    console.log("openChangePasswordModal called");
    this.showChangePasswordModal = true;
  }

  openDeleteProfileModal(): void {
    console.log("openDeleteProfileModal called");
    this.showDeleteProfileModal = true;
  }

  ngOnDestroy(): void {
  }

  showUpdateConfirmModal(){
    this.message="Si è sicuri di salvare le modifiche al proprio profilo?"
  }

  updateProfile() {
    this.message='';
    this.loading = true;

    console.log("user updating: ", this.user);

    const headers= this.authService.generateHeader();

    const body = {
      'first_name': this.user.first_name,
      'last_name': this.user.last_name
    }

    console.log("Update profile called");
    this.http.put(environment.API_URL+"profile", body, {headers})
    .subscribe({
      next: (res: any) => {

      },
      error: (err: any) => {

        this.loading = false;
        this.router.navigate(['/profile'], { state: { error: true } });

        console.log("Update error: ", err)
      },
      complete: () => {

        this.loading = false;
        this.router.navigate(['/profile'], { state: { success: true } });

        console.log("User updated successfully");
      }
    })
  }

}
