<div class="custom-header text-white d-flex justify-content-between" *ngIf="isLoggedIn">

	<app-confirm-modal class="logout-confirm-modal" [message]="message" *ngIf="message!=''" (close)="message = ''" (action)="logout()"></app-confirm-modal>

	<div class="ps-2 fs-1 d-flex align-items-center">
        <div (click)="toggleSidenav()" class="menu-button">
        <i class="bi bi-list"></i>
        </div>
		<div class="ms-5 custom-breadcrumb">
			<xng-breadcrumb></xng-breadcrumb>
		</div>
    </div>
    <div class="header-title d-flex align-items-center">
        {{app_name}}
    </div>

	<div class="p-2 header-user d-flex align-items-center">
		<div style="width: 20vh">
		</div>
		<div class="dropdown">
			<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
				{{name}}
			</button>
			<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
				<li class="dd-item"><a class="dropdown-item" routerLink="/profile">Profile</a></li>
				<li class="dd-item"><a class="dropdown-item" (click)="showLogoutConfirmModal()">Logout</a></li>
			</ul>
		</div>
	</div>

</div>

<div class="custom-header text-white text-center" *ngIf="!isLoggedIn">
	<div class="header-title">
		{{app_name}}
	</div>
</div>


