import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {


  constructor(private auth : AuthService, private router : Router){

  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    //console.log('AuthWithRedirectGuard.canActivate');
    const isAdmin = await this.auth.isAdmin();

	console.log("AdminGuard called");
	//console.log(`Admin guard state' ${state.url}`); // the url you are going to
    //console.log('canActivate await value: ' + isAdmin);

    if (!isAdmin) {
      await this.router.navigate(['/']);
    }
    return isAdmin;
  }

}


