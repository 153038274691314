
<div class="backdrop"></div>
<div class="alert-box">

    <div *ngIf="!passwordUpdateSuccess">
        <div *ngIf="id===''" class="mb-3">
            <label for="OldPasswordInput" class="form-label">Vecchia password:</label>
            <input [(ngModel)]="oldPassword" type="password" class="form-control" id="OldPasswordInput" placeholder="Immetti la vecchia password">
        </div>

        <div class="mb-3">
            <label for="NewPasswordInput" class="form-label">Nuova password:</label>
            <input [(ngModel)]="newPassword" type="password" class="form-control" id="NewPasswordInput" placeholder="Immetti la nuova password">
        </div>

        <div class="mb-3">
            <label for="NewPasswordConfirm" class="form-label">Conferma nuova password:</label>
            <input [(ngModel)]="newPasswordConfirm" type="password" class="form-control" id="NewPasswordConfirm" placeholder="Immetti nuovamente la nuova password">
        </div>


        <div *ngIf="loading" class="d-flex justify-content-center mt-3">
            <div  class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="mb-3">
            <small *ngIf="errorMessage" class="text-danger">{{errorMessage}}</small>
        </div>
    </div>

    <div *ngIf="passwordUpdateSuccess" class="mb-3">
        <small *ngIf="errorMessage" class="text-success">{{errorMessage}}</small>
    </div>

    <div class="alert-box-actions mt-3 text-end">
        
        <button *ngIf="!passwordUpdateSuccess" type="button" class="btn btn-danger" (click)="onCancelClick()">Cancel</button>

        <button *ngIf="!passwordUpdateSuccess" type="button" class="btn btn-primary ms-3 text-right" (click)="onConfirmClick()">Ok</button>

        <button *ngIf="passwordUpdateSuccess && id===''" type="button" class="btn btn-success ms-3 text-right" (click)="logoutRedirect()">Ok</button>

        <button *ngIf="passwordUpdateSuccess && id!==''" type="button" class="btn btn-success ms-3 text-right" (click)="onCancelClick()">Ok</button>

    </div>

</div>