<div *ngIf="!loading" class="email-update">

    <div class="text-center">
        <img class="mb-4" src="assets/icons/icon-152x152.png">
    </div>

    <div *ngIf="successfulResponse" class="text-center text-success">
        L'email è stata correttamente aggiornata<br><br>
        <button class="btn btn-primary" (click)="onNavigateBackToHome()">Torna alla home</button>
    </div>

    <div *ngIf="!successfulResponse" class="text-center text-danger">
        C'è stato un errore durante l'aggiornamento dell'email<br>si prega di riprovare più tardi
    </div>

</div>
